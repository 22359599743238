import { observer } from "mobx-react-lite";
import { KanbanBoard } from "./KanbanBoard";
import { useContext } from "react";
import { MainStoreContext } from "../../stores/MainStore";
import { BoardStage } from "../../model/Kanban";
import React from "react";

export type KanbanContainerProps = {
  orgId: number;
  handleRemovePlayer: any;
  recruitingClass: number;
  scoutId: string;
  updateCard: any;
}
  

const KanbanContainer = observer((props: KanbanContainerProps) => {
  const mainStore = useContext(MainStoreContext);
  const store = mainStore.kanbanStore;




  return (

      <KanbanBoard
        key="KanbanBoard"
        board={store.board}
        organizationId={props.orgId}
        onMoveCard={(fromStageIdx, fromCardIdx, toStageIdx, toCardIdx) => {
          store.moveCard(fromStageIdx, fromCardIdx, toStageIdx, toCardIdx);
        }}
        onMoveStage={(fromIdx, toIdx) => {
          store.moveStage(fromIdx, toIdx);
        }}
        onDeleteCard={(stageIdx, cardIdx) => {
          const removedId = store.removeCard(stageIdx, cardIdx);
          props.handleRemovePlayer(removedId);
        }}
        onRenameStage={(stageIdx, toName) => {
          store.renameStage(stageIdx, toName);
        }}
        onDeleteStage={(stageIdx) => {
          store.removeStage(stageIdx);
        }}
        addStage={(stage: BoardStage) => {
          store.addStage(stage);
        }}
        scoutId={props.scoutId}
        updateCard={props.updateCard}
      />

);
});
export default KanbanContainer;
