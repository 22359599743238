import React from "react";
import Dialog from "@mui/material/Dialog";
import { useEffect, useRef, useState } from "react";
import { Input, DialogActions, DialogContent, CircularProgress } from "@mui/material";
import {ImageFileData} from "./ImageFileData";
import { CustomButton } from "../../components/Button";
import styles from "../../styles/ImageUpload.module.scss";

export interface FileUploadDialogProps {
 open: boolean;
 selectedValue: string;
 onClose: (value: any) => void;
}


function FileUploadDialog(props: FileUploadDialogProps) {
 const { onClose, open } = props;
 const [selectedFile, setSelectedFile] = useState<any>();
 const [lastModifiedDateString, setLastModifiedDateString] = useState("");
 const [encodedFile, setEncodedFile] = useState<any>();
 const [isFilePicked, setIsFilePicked] = useState(false);
 const uploadInputRef = useRef<any>(null);
 const [showProgress, setShowProgress] = useState(false);
 const handleClose = () => {
  setShowProgress(true);
  window.setTimeout(() => {
    onClose(encodedFile);
  }, 300);

 };
 const handleCancel = () => {
  setSelectedFile(null);
  onClose("");
 };

 useEffect(()=>{
  setShowProgress(false);
}, [open]);

 const configUploadChangeHandler = (event:any) => {
  setSelectedFile(event.target.files[0]);
  let configFileBase64 = "";
  const lastModifiedString = new Date(
   event.target.files[0].lastModified
  ).toLocaleDateString();
  setLastModifiedDateString(lastModifiedString);
  getBase64(event.target.files[0], (result: any) => {
   configFileBase64 = result;
   const configFile: ImageFileData = {
    FileData: result.substr(result.indexOf(",") + 1),
    FileName: event.target.files[0].name,
   };
   setEncodedFile(configFile);
  });
  setIsFilePicked(true);
 };
 function getBase64(file: any, cb:any) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
   cb(reader.result);
  };
  reader.onerror = function (error) {
   console.log("Error: ", error);
  };
 }


 return (
  <Dialog onClose={handleClose} open={open}>
    <div className={styles.dialogHeader}>Select Picture</div>

   <DialogContent dividers>
   
    {showProgress && (
    <div className={styles.progressDiv}>
      <CircularProgress className={styles.child} />
    </div>
    )} 
    <label htmlFor="send-config">
     <Input
      type="file"
      ref={uploadInputRef}
      style={{ display: "none" }}
      name="file"
      onChange={configUploadChangeHandler}
      id="send-config"
     />
     <div className={styles.selectContainer}>
       <CustomButton onClick={() => uploadInputRef.current && uploadInputRef.current.click()} title="Select File" 
       />
     </div>


    </label>
    {isFilePicked && selectedFile ? (
     <div className={styles.selectedFileContainer}>
      <img width={"256px"} alt="Your Pic" src={URL.createObjectURL(selectedFile)} />
      <p className={styles.fileName}>
        File Name: {selectedFile.name}
      </p>
      <p>
       File Type: {selectedFile.type}
      </p>
      <p>
       Size in Bytes: {selectedFile.size}
      </p>
      <p>
       Last Modified Date: {lastModifiedDateString}
      </p>
     </div>
    ) : (
     <p>Select a file to show details</p>
    )}
   </DialogContent>
   <DialogActions>
      <span className={styles.actionsContainer}>
      <CustomButton title="Submit" onClick={handleClose} />

      </span>
      <span className={styles.actionsContainer}>
      <CustomButton title="Cancel" onClick={handleCancel}/>
      </span>
   </DialogActions>
  </Dialog>
 );
}


export default FileUploadDialog;


