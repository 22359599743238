import {  IconButton} from "@mui/material";
import React, { useEffect, useState } from "react";

import type {} from '@mui/x-date-pickers/themeAugmentation';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
export type EditFieldProps = {
  value: string;
  handleSave: any;
  handleCancel: any;
  type: string;
  showInputEle: boolean;
  handleChange: any;
  handleClick: any;
};
function EditField(props: EditFieldProps) {
  const [text, setText] = useState(props.value);
  const saveClick = () => {
    props.handleSave();
  }  
  const cancelClick = () => {
    props.handleCancel();
  }
  const [arrayIndex, setArrayIndex] = useState(2);

  useEffect(() => {
    if(props.showInputEle === true){
      //show the edit controls
      if(props.type === 'text'){
        setArrayIndex(0);     
      } else if(props.type === 'position'){

      } else if(props.type === 'date'){

      } else if(props.type === 'height') {

      } else if(props.type === 'weight'){

      }

    } else if(props.showInputEle === false){
      //show the display controls
      if(props.type === 'text'){
        setArrayIndex(2);
      } else if(props.type === 'position'){

      } else if(props.type === 'read'){
        setArrayIndex(4);
      } else if(props.type === 'date') {

      } else if(props.type === 'height') {

      } else if (props.type === 'weight'){

      }
    }
  }, [props.type, props.value, props.showInputEle]);
  
  return(
    <span>
      {{
        0: <input
            type="text"
            value={text}
            onChange={(e)=>{
              setText(e.target.value);
              props.handleChange(e.target.value);
            }}
            autoFocus
          />,
        2: (<span
              style={{
                display: "inline-block",
                height: "25px",
                minWidth: "150px",
              }}
            >
              {text}
            </span> ),
        4: (<span
          style={{
            display: "inline-block",
            height: "25px",
            minWidth: "150px",
          }}
        >
          {text}
        </span> ),  
      }[arrayIndex]}
      { props.showInputEle && (      
      <div>
      <IconButton
      onClick={saveClick}
      aria-label="save">
        <CheckCircleOutlineOutlinedIcon />
      </IconButton>
      <IconButton
        onClick={cancelClick}
      aria-label="cancel">
        <CancelOutlinedIcon />
      </IconButton>
      </div>
      )}

      </span>
  );
 }



export default EditField;