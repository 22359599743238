import React from "react";
import { useEffect, useState } from "react";
import styles from "../../styles/InfoCell.module.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export type WeightEditorProps = {
  icon?: string;
  label: number;
  sub: string;
  queryField: string;
  type: string;
  handleSave: any;
};

export const WeightEditor = (props: WeightEditorProps) => {
  //let text = "-"
  const [adjustedValue, setAdjustedValue] = useState(0);
  const [displayValue, setDisplayValue] = useState("");
  const[showInputElement, setShowInputElement] = useState(false);
  useEffect(()=> {
    setDisplayValue(weightConversion(props.label));
    setAdjustedValue(Math.round(props.label * 2.20462));
  }, [props.label])
  const weightConversion = (weightInKg: number) => {
    if (Number.isNaN(weightInKg) || weightInKg == null || weightInKg == undefined || weightInKg === 0) {
      return "-";
    }
    const weightInLbs = weightInKg * 2.20462;
    return Math.round(weightInLbs) + " lbs";
  };
  function handleWeightChange(value){
    setAdjustedValue(value);
    setDisplayValue(value.toString() + " lbs");
  }
  function handleSave(){
    if (adjustedValue !== undefined) {
        props.handleSave(Math.round(adjustedValue/2.20462), props.queryField, props.type);
        setShowInputElement(false);      
    }
  }
  const handleClick = () => {
    setShowInputElement(true);
  }
  function handleCancel() {
    setShowInputElement(false);
  }
  return <div className={styles.container}>
    <div className={styles.labelText}>
    <span onClick={handleClick}
        style={{
          display: "inline-block",
          height: "25px",
          minWidth: "150px",
        }}
      >
      {props.sub}
      </span>    
    </div>
    <div>
      <div>
        {showInputElement ? (
          <div>
              <TextField
              label="Player Weight"
              id="filled-start-adornment"
              value={adjustedValue}
              InputProps={{
                endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              }}
              onChange={(e)=>{
                handleWeightChange(e.target.value);
              }}
              sx={{ m: 1, width: '120px' }}
            />  
            <div>
            <IconButton
            onClick={handleSave}
            aria-label="save">
              <CheckCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleCancel}
            aria-label="cancel">
              <CancelOutlinedIcon />
            </IconButton>
            </div>
            </div>
        ) : (
          <span onClick={handleClick}
                  style={{
                    display: "inline-block",
                    height: "25px",
                    minWidth: "150px",
                  }}
                >
                  {displayValue}
                </span>
        )
        }
      </div>
    </div>
  </div>;
};