import React from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { EnvironmentInfo } from "../model/EnvironmentInfo";
import { APIService } from "../services/APIService";
import { CollaborationService } from "../services/CollaborationService";
import { KanbanStore } from "./KanbanStore";


export class MainStore {
  apiService: APIService;
  environment: EnvironmentInfo;
  isReady: boolean;
  scoutId: string;
  // Other global stores
  kanbanStore: KanbanStore;
  setScoutId(id: string){
    this.scoutId = id;
  }
  constructor() {
    this.isReady = false;
    this.apiService = new APIService();
    makeAutoObservable(this);
  }

  async fetchStaticData(OrganizationId: number, RecruitingClass: number): Promise<void> {
    try {
      //const environment = await this.apiService.getEnvironmentInfo();
      runInAction(() => {
       // this.environment = environment;
        this.createStores(OrganizationId, RecruitingClass);
        this.isReady = true;
      });
    } catch (e) {

    }
  }

  createStores(OrganizationId: number, RecruitingClass: number) {
    const collaborationService = new CollaborationService(process.env.REACT_APP_SHAREDB_URL, `alq${OrganizationId}`, `board${RecruitingClass}`, null, WebSocket);

    this.kanbanStore = new KanbanStore(collaborationService, this.scoutId);
  }
}

export const MainStoreContext = React.createContext<MainStore>(null);