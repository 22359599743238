import { MdLocationPin } from "react-icons/md";
import { RiGraduationCapFill } from "react-icons/ri";
import { VscJersey } from "react-icons/vsc";
import jerseyIcon from "./icons/jersey.svg"
import gradIcon from "./icons/graduation.svg"
import locationIcon from "./icons/location.svg"
import styles from "../../../styles/KanbanCard.module.scss"

export const PlayerDetails = ({ card, onViewProfile, getPlayerPositions }) => {
  return (
    <div className={styles.playerDetailsWrapper} onClick={() => onViewProfile(card)}>
      <div className={styles.playerInfoWrapper}>
        <div className={styles.avatar}>
          <img
            key={`CardAvatar-${card.id}`}
            src={`/icons/nophoto.png`}
            width="40"
            height="40"
            alt="Player"
          />
        </div>

        <div className={styles.info}>
          <div className={styles.name} >
            {card.user.name}
          </div>
          {card.user.playerPositions && card.user.playerPositions.length > 0 ? (
            <div className={styles.positions}>
              Positions: {getPlayerPositions(card.user.playerPositions)}
            </div>
          ) : (
            <div className={styles.positions}>Positions: N/A</div>
          )}
        </div>
      </div>

      <div className={styles.wrapper2}>
        <div className={styles.wrapper3}>
          <span className={styles.playerDetails}>
            <img src={locationIcon} className={styles.playerIcons}/>
            {card.user.area ? card.user.area : "N/A"}
          </span>
          <span className={styles.playerDetails}>
            {/* <RiGraduationCapFill className={styles.icons} /> */}
            <img src={gradIcon} className={styles.playerIcons}/>
            {card.user.class ? card.user.class : "N/A"}
          </span>
        </div>
        <div className={styles.playerDetails}>
          {/* <VscJersey className={styles.icons} /> */}
          <img src={jerseyIcon} className={styles.playerIcons}/>
          {card.user.club ? card.user.club : "N/A"}
        </div>
      </div>
    </div>
  );
};

