import { resolve } from "path/posix";
import ReconnectingWebSocket from "reconnecting-websocket";
import {Connection, Doc} from "sharedb/lib/client";

let connection: Connection;

export class OperationBuilder {
  ops: any[];

  constructor() {
    this.ops = [];
  }

  // Numeric operations
  addToNumber(path: any[], value: number): OperationBuilder {
    this.ops.push({p: path, na: value});
    return this;
  }

  // List operations
  insertBefore(path: any[], idx: number, object: any): OperationBuilder {
    this.ops.push({p: [...path, idx], li: object});
    return this;
  }

  deleteAt(path: any[], idx: number, object: any): OperationBuilder {
    this.ops.push({p: [...path, idx], ld: object});
    return this;
  }

  replaceAt(path: any[], idx: number, before: any, after: any): OperationBuilder {
    this.ops.push({p: [...path, idx], ld: before, li: after});
    return this;
  }

  move(path: any[], from: number, to: number): OperationBuilder {
    this.ops.push({p: [...path, from], lm: to});
    return this;
  }

  // Object operations
  insertObject(path: any[], key: string, object: any): OperationBuilder {
    this.ops.push({p: [...path, key], oi: object});
    return this;
  }

  deleteObject(path: any[], key: string, object: any): OperationBuilder {
    this.ops.push({p: [...path, key], od: object});
    return this;
  }

  replaceObject(path: any[], key: string, before: any, after: any): OperationBuilder {
    this.ops.push({p: [...path, key], od: before, oi: after});
    return this;
  }

  // String operations
  insertString(path: any[], offset: number, str: string): OperationBuilder {
    this.ops.push({p: [...path, offset], si: str});
    return this;
  }

  deleteString(path: any[], offset: number, str: string): OperationBuilder {
    this.ops.push({p: [...path, offset], sd: str});
    return this;
  }
}


export class CollaborationService {
  serverUrl: string;
  collectionId: string;
  documentId: string;
  onUpdate: VoidFunction;

  private websocket: any;

  private doc: Doc;

  get document(): any {
    return this.doc.data;
  }

  get version(): number {
    return this.doc.version;
  }

  constructor(serverUrl: string, collectionId: string, documentId: string, onUpdate: VoidFunction, websocket: any) {
    this.serverUrl = serverUrl;
    this.collectionId = collectionId;
    this.documentId = documentId;
    this.websocket = websocket;
    this.onUpdate = onUpdate;
  }

  subscribe(): Promise<void> {
    this.connect();
    this.doc = connection.get(this.collectionId, this.documentId);

    return new Promise<void>((resolve, reject) => {
      // Setup event handlers
      this.doc.on("op", () => {
        if (this.onUpdate !== null) this.onUpdate();
      });

      // Subscibe to the document changes
      this.doc.subscribe((error) => {
        if (error) {
          reject(error);
          return;
        };

        if (this.doc.type != null) {
          // Document exists, resolve the promise
          resolve();
          return;
        }

        // Create the new empty documents
        this.doc.create({}, (error) => {
          if (error) {
            reject(error);
          }
          resolve();
        });
      });
    });
  }

  delete(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.doc.del({}, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    });
  }

  // Document mutation operations
  submitOperation(operation: OperationBuilder): Promise<void> {
    return this.submitRawOp(operation.ops);
  }

  // Submit raw OT operation
  submitRawOp(op: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.doc.submitOp(op, {}, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    });
  }

  // Connection management

  // WebSocket connect
  connect() {
    // Share the same WebSocket connection between all collaborative documents
    if (connection !== undefined) return;
    const socket = new ReconnectingWebSocket(this.serverUrl, [], {
      WebSocket: this.websocket,
      connectionTimeout: 5000,
      maxRetries: 10,
      //debug: true,
    });
    connection = new Connection(socket);
  }

  // WebSocket disconnect
  disconnect() {
    // We don't need this function in the web app because it needs to always maintain the connection
    // This function may be needed only in integration tests with a real ShareDB instance
    connection.close();
    connection = undefined;
  }
}