import { useState, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { KanbanItemTypes } from "./constants";
import { BoardCard, BoardStage } from "../../model/Kanban";
import styles from "../../styles/KanbanCard.module.scss";
import { FcCheckmark } from "react-icons/fc";
import { PlayerPanel } from "../player/PlayerPanel";
import moreIcon from "../../icons/more.svg";
import {
  MoveCardCallback,
  DeleteCardCallback,
} from "./KanbanTypes";
import { Drawer } from "@mui/material";
import UserApi from "../../appsync/userApi";
import { User } from "../../model/User";
import { PlayerDetails } from "./KanbanCardSubComponents/PlayerDetails";
import { DeletePlayerModal } from "./KanbanCardSubComponents/DeletePlayerModal";

declare type KanbanCardProps = {
  card: BoardCard;
  cardIdx: number;
  stageIdx: number;
  stages: BoardStage[];
  onMoveCard: MoveCardCallback;
  onDeleteCard: DeleteCardCallback;
  updateCard: any;
  scoutId: string;
};

const CardContent = ({
  card,
  onDeleteCard,
  stageIdx,
  cardIdx,
  scoutId,
  stages,
  updateCard,
  onMoveCard,
}: KanbanCardProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [overlayUser, setOverlayUser] = useState(null);
  const dropdownRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [isMoveToMenuOpen, setMoveToMenuOpen] = useState(false);
  const [isMoveSubMenuOpen, setMoveSubMenuOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  const propigateChanges = ()=> {
    UserApi.getPlayer(card.user.id, scoutId).then((player: any) => {
      const element = player.data.getPlayerDetail;
      card.user.area = element.Area;
      card.user.club = element.ClubName;
      card.user.playerPositions = element.PlayerPositions;
      card.user.updated = true;
      updateCard(stageIdx, cardIdx, card);
    });
  }
  useEffect(() => {
    if (card === null || card.user.updated === true || card.user === null || (card.user.area != null && card.user.club != null)) return;
    UserApi.getPlayer(card.user.id, scoutId).then((player: any) => {
      const element = player.data.getPlayerDetail;
      card.user.area = element.Area;
      card.user.club = element.ClubName;
      card.user.playerPositions = element.PlayerPositions;
      card.user.updated = true;
      updateCard(stageIdx, cardIdx, card);
    });
  }, [card, cardIdx, scoutId, stageIdx, updateCard])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (isDropdownOpen) {
          setDropdownOpen(false);
        }
        if (isMoveSubMenuOpen) {
          setMoveSubMenuOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, isMoveSubMenuOpen]);

  const handleToggleDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = () => {
    setModalOpen(true);
    setDropdownOpen(false);
  };

  const confirmDeletion = () => {
    onDeleteCard(stageIdx, cardIdx);
    setModalOpen(false);
  };

  const handleMoveToClick = () => {
    setMoveSubMenuOpen(!isMoveSubMenuOpen);
  };

  const moveCardToStage = (targetStageIndex) => {
    if (targetStageIndex !== stageIdx) {
      onMoveCard(stageIdx, cardIdx, targetStageIndex, cardIdx);
      setMoveToMenuOpen(false);
      setMoveSubMenuOpen(false);
      setDropdownOpen(false);
    }
  };

  const onViewProfile = (card: BoardCard) => {
    UserApi.getPlayer(card.user.id, scoutId).then((player: any) => {
      const element = player.data.getPlayerDetail;
      const selectedUser: User = {
        id: element.Id,
        name: element.DisplayName,
        playerPositions: element.PlayerPositions,
        email: element.Email,
        phone: element.Phone,
        lead: element.Lead,
        class: element.Class,
        inKanban: element.InKanban,
        picture: "/icons/nophoto.png",
        birthDate: element.Birthdate,
        club: element.ClubName,
        country: element.Country,
        area: element.Area,
        playerHeight: element.PlayerHeight,
        playerWeight: element.PlayerWeight,
        playerFoot: element.PlayerFoot,
        links: element.Links,
        notes: element.Notes,
      };
      setOverlayUser(selectedUser);
      setDropdownOpen(false);
      toggleDrawer();
    });
  };

  const getPlayerPositions = (positions: number[]) => {
    const positionsLabels = [
      "LWB",
      "RWB",
      "D",
      "G",
      "GK",
      "K",
      "B",
      "CFB",
      "RFB",
      "LFB",
      "CB",
      "RCB",
      "LCB",
      "TCB",
      "ST",
      "CD",
      "SW",
      "L",
      "OFB",
      "OB",
      "RB",
      "LB",
      "M",
      "MF",
      "CHB",
      "RHB",
      "LHB",
      "CM",
      "AM",
      "CAM",
      "HM",
      "DM",
      "CDM",
      "PM",
      "OM",
      "RM",
      "LM",
      "WB",
      "WM",
      "F",
      "CF",
      "IF",
      "RI",
      "RF",
      "LI",
      "LF",
      "W",
      "RW",
      "LW",
      "S",
      "CS",
      "RCF",
      "LCF",
      "LCF",
      "TCF",
      "WF",
      "SS",
      "WS",
      "RWS",
      "LWS",
      "DCM",
    ];
    return positions
      .map((position) => positionsLabels[position - 1])
      .join(", ");
  };

  const filteredStagesWithOriginalIndex = stages
    .map((stage, index) => ({ stage, originalIndex: index }))
    .filter(({ stage }) =>
      stage.title.toLowerCase().includes(searchInput.toLowerCase())
    );

  return (
    <div className={styles.layout}>
      <div className={styles.wrapper1}>
        <PlayerDetails
          card={card}
          onViewProfile={onViewProfile}
          getPlayerPositions={getPlayerPositions}
        />
        <div className={styles.moreWrapper}>
          <div className={styles.more} onClick={handleToggleDropdown}>
            <img className={styles.icon} src={moreIcon} alt="dropdown menu" />
          </div>
          {isDropdownOpen && (
            <div className={styles.dropdown} ref={dropdownRef}>
              <div
                className={styles.options}
                onClick={() => onViewProfile(card)}
              >
                Edit player
              </div>
              <div className={styles.options} onClick={handleMoveToClick}>
                Move player to...
              </div>
              <div
                className={`${styles.moveSubMenu} ${
                  isMoveSubMenuOpen ? styles.open : ""
                }`}
              >
                <div className={styles.inputWrapper}>
                  <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Type a list..."
                    className={styles.searchInput}
                  />
                </div>

                {filteredStagesWithOriginalIndex.map(
                  ({ stage, originalIndex }) => (
                    <div
                      key={originalIndex}
                      className={styles.moveOption}
                      onClick={() => moveCardToStage(originalIndex)}
                    >
                      {stage.title}
                      {originalIndex === stageIdx && (
                        <FcCheckmark
                          style={{ marginRight: "4px" }}
                          size="16px"
                        />
                      )}
                    </div>
                  )
                )}
              </div>
              <div
                className={styles.options}
                onClick={handleOptionClick}
                style={{
                  color: "#EB5757",
                  borderTop: "1px solid #D9D9D9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                Delete player
              </div>
            </div>
          )}
        </div>
      </div>
      <Drawer anchor="right" open={drawerState} onClose={toggleDrawer}>
        <PlayerPanel
          cardIdx={cardIdx}
          stageIdx={stageIdx}
          player={overlayUser}
          closeDrawer={toggleDrawer} 
          propigateUpdate={propigateChanges}        
          />
      </Drawer>
      <DeletePlayerModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        confirmDeletion={confirmDeletion}
      />
    </div>
  );
};

export function KanbanCard(props: KanbanCardProps) {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: KanbanItemTypes.CARD,
    item: { cardIdx: props.cardIdx, stageIdx: props.stageIdx },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [collectedDrop, drop] = useDrop(() => ({
    accept: KanbanItemTypes.CARD,
    drop: (item, monitor) => {
      props.onMoveCard(
        item["stageIdx"],
        item["cardIdx"],
        props.stageIdx,
        props.cardIdx
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
  }));

  const isOver = collectedDrop.isOver;
  const showDropArea = isOver && collectedDrop.item["id"] !== props.card.id;

  // Different render states
  if (isDragging && isOver) {
    // Dragging item, render placeholder
    return (
      <div ref={drop} className={styles.cardBack}>
        <div className={`${styles.card} ${styles.placeholder}`}></div>
      </div>
    );
  } else if (isDragging && !isOver) {
    // Dragging item, not over - render nothing
    return <div></div>;
  } else if (showDropArea) {
    // Add area below the item to put
    return (
      <div ref={drop} className={styles.cardBack}>
        <div className={`${styles.card} ${styles.placeholder}`}></div>
        <div className={`${styles.card} ${styles.content}`} ref={drag}>
          <CardContent {...props} />
        </div>
      </div>
    );
  } else {
    // Normal rendering
    return (
      <div ref={drop} className={styles.cardBack}>
        <div className={`${styles.card} ${styles.content}`} ref={drag}>
          <CardContent {...props} />
        </div>
      </div>
    );
  }
}
