import React from "react";
import styles from "../styles/Header.module.scss";
import { CustomButton } from "./Button";
import { useAuthenticator } from '@aws-amplify/ui-react';


declare type HeaderProps = {
  title?: React.ReactNode;
  name: string;
  orgLogoUrl: string;
  orgName: string;
}

export default function Header({title, name, orgLogoUrl, orgName}: HeaderProps) {

  const currentHour = new Date().getHours();

  let timeOfDay;
  if (currentHour < 12) {
    timeOfDay = "morning";
  } else if (currentHour < 18) {
    timeOfDay = "afternoon";
  } else {
    timeOfDay = "evening";
  }

  const { signOut } = useAuthenticator((context) => [context.user]);
  return (
  <div className={styles.flexcontainer + " " + styles.header}>
    <div className={styles.flexchild}>
      <div className={styles.flexcontainer + " " + styles.sidebarHeader}>
        <img
          className={styles.headerImage}
          src={process.env.REACT_APP_USER_IMAGE_BASE_URL + orgLogoUrl}
          width="80"
          height="80"
          alt={""}
        />
        <div className={styles.sidebarHeaderText}>{orgName}</div>
        
      </div>

    </div>
    <div className={styles.flexchild + " " + styles.mainHeader}>
      <div className={styles.flexcontainer }>
        <div className={styles.headerText + ' ' + styles.flexchild}>
          {`Good ${timeOfDay}, ${name}`}
        </div>
        <div className={styles.flexchild}>
          <CustomButton
            title="Sign Out"
            onClick={signOut}
          />
        </div>  
      </div>
    </div>  
  </div>
);
}