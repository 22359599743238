import React from "react";
import { useEffect, useState } from "react";
import styles from "../../styles/InfoCell.module.scss";
import { Chip, IconButton} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MultipleSelectChip from "../MultipleSelectChip";

export type PositionsEditorProps = {
  icon?: string;
  label: number[];
  sub: string;
  queryField: string;
  type: string;
  handleSave: any;
};

export const PositionsEditor = (props: PositionsEditorProps) => {
  const [displayValue, setDisplayValue] = useState([]);
  const [showInputElement, setShowInputElement] = useState(false);

  useEffect(()=> {
    if(props.label != null && props.label != undefined){
      setDisplayValue(props.label);
    }
  }, [props.label]);
  const [newPositions, setNewPositions] = useState([]);
  const positions =  ["LWB", "RWB", "D", "G", "GK", "K", "B", "CFB", "RFB", "LFB", "CB", "RCB", "LCB", "TCB", "ST", "CD", "SW","L", "OFB", "OB", "RB", "LB",  "M", "MF", "CHB","RHB","LHB","CM","AM","CAM", "HM","DM", "CDM", "PM", "OM", "RM", "LM", "WB", "WM",  "F", "CF","IF",  "RI", "RF",  "LI", "LF", "W",  "RW", "LW", "S",  "CS", "RCF", "LCF",  "LCF", "TCF", "WF", "SS", "WS", "RWS", "LWS", "DCM"];
  const positionNames = (playerPositions: number[]) => {

    if (playerPositions === null  || playerPositions.length < 1) {
      return "-";
    }
    const returnString = playerPositions.map(position => {
      const index = position;
      return <Chip key={index} label={positions[index -1] + " "} variant="outlined" />;
    });
    return returnString;
  }
  const handlePositionChange =(values: string[]) =>{
    setNewPositions(values);
    setDisplayValue(values);
  }
  function handleSave(){
    props.handleSave(newPositions, props.queryField, props.type);
    setShowInputElement(false);
  }
  function handleClick(){
    setShowInputElement(true);
  }
  function handleCancel() {
    setShowInputElement(false);
  }
  return <div className={styles.container}>
    <div>
      <div className={styles.labelText}>
        <span onClick={handleClick}
            style={{
              display: "inline-block",
              height: "25px",
              minWidth: "170px",
            }}
          >
          {props.sub}
          </span>    
        </div>
        {showInputElement ? (
          <div>
              <MultipleSelectChip handleValueChange={handlePositionChange} />
              <div>
              <IconButton
              onClick={handleSave}
              aria-label="save">
                <CheckCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={handleCancel}
              aria-label="cancel">
                <CancelOutlinedIcon />
              </IconButton>
              </div>
            </div>
        ) : (
              <span
              onClick={handleClick}
              style={{
                display: "inline-block",
                height: "25px",
                minWidth: "150px",
              }}
            >
              {positionNames(displayValue)}
            </span>           
        )
        }
      </div>
    </div>;
};