import React from "react";
import { useEffect, useState } from "react";
import styles from "../../styles/InfoCell.module.scss";
import { Box, IconButton, InputAdornment, Slider, TextField } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export type HeightEditorProps = {
  icon?: string;
  label: number;
  sub: string;
  queryField: string;
  type: string;
  handleSave: any;
};

export const HeightEditor = (props: HeightEditorProps) => {
  const [adjustedValue, setAdjustedValue] = useState(props.label);
  const [displayValue, setDisplayValue]  = useState(`5' 0"`);
  let text = props.label;
  const[showInputElement, setShowInputElement] = useState(false);
  if(props.label != null && props.label != undefined && props.label != 0){
    text = props.label ;
  }

  useEffect(()=>{
    const imperial = Math.round(props.label/2.54) ;
    setAdjustedValue(imperial);
    const feet = Math.floor(imperial / 12);
    const inches = imperial % 12;
    const returnString = `${feet}' ${inches}'`;
      
    setDisplayValue(returnString);
  }, [props.label])
  function heightText(event) {
    const feet = Math.floor(event.target.value / 12);
    const inches = event.target.value % 12;
    const returnString = `${feet}' ${inches}'`;
    setDisplayValue(returnString);
    setAdjustedValue(event.target.value * 2.54);
    text = event.target.value * 2.54;
  }


  function handleSave(){
    props.handleSave(adjustedValue, props.queryField, props.type);
    setShowInputElement(false);
  }
  function handleClick(){
    setShowInputElement(true);
  }
  function handleCancel() {
    setShowInputElement(false);
  }
  return <div className={styles.container}>
    <div className={styles.labelText}>
    <span onClick={handleClick}
        style={{
          display: "inline-block",
          height: "25px",
          minWidth: "170px",
        }}
      >
      {props.sub}
      </span>    
    </div>
    <div>
      <div>
        {showInputElement ? (
          <div>
            <Box sx={{ width: 150 }}>
              <Slider
                aria-label="Small steps"
                defaultValue={64}
                onChange={heightText}
                step={1}
                min={50}
                max={84}
                valueLabelDisplay="off"
              />
                {displayValue}
              </Box> 
              <div>
              <IconButton
              onClick={handleSave}
              aria-label="save">
                <CheckCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={handleCancel}
              aria-label="cancel">
                <CancelOutlinedIcon />
              </IconButton>
              </div>
            </div>
        ) : (
          <span
                  onClick={handleClick}
                  style={{
                    display: "inline-block",
                    height: "25px",
                    minWidth: "150px",
                  }}
                >
                  {displayValue}
                </span>
        )
        }
      </div>
    </div>
  </div>;
};