import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import { FormHelperText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const positions =  [
  { id: 5, name: "GK" },
  { id: 17, name: "SW" },
  { id: 11, name: "CB" },
  { id: 22, name: "LB" },
  { id: 21, name: "RB" },
  { id: 1, name: "LWB" },
  { id: 2, name: "RWB" },
  { id: 33, name: "CDM" },
  { id: 12, name: "RCB" },
  { id: 13, name: "LCB" },
  { id: 30, name: "CAM" },
  { id: 37, name: "LM" },
  { id: 36, name: "RM" },
  { id: 15, name: "ST" },
  { id: 49, name: "LW" },
  { id: 48, name: "RW" },
  { id: 3, name: "D" },
  { id: 40, name: "F" },
  { id: 23, name: "M" },
  { id: 47, name: "W" },  
  { id: 28, name: "CM" },  
  { id: 31, name: "HM" },    
  { id: 38, name: "WB" },    
];

function getStyles(name: string, positionId: readonly string[], theme: Theme) {
  return {
    fontWeight:
      positionId.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

declare type MultipleSelectProps = {
  handleValueChange: any;
}
export default function MultipleSelectChip(props: MultipleSelectProps) {
  const theme = useTheme();
  const [positionId, setPositionId] = useState<string[]>([]);
  const handleChange = (event: any) => {
    setPositionId(event.target.value);
    props.handleValueChange(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="position-select-label">Position</InputLabel>
        <Select
          labelId="position-select-label"
          id="position-select"
          multiple
          value={positionId}
          onChange={handleChange}
          input={<OutlinedInput id="select-position" label="Position" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={positions != null? positions.find(x => x.id == parseInt(value)).name: ""} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {positions.map((position) => (
            <MenuItem
              key={position.id}
              value={position.id}
              style={getStyles(position.name, positionId, theme)}
            >
              {position.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Please select the players position(s)</FormHelperText>
      </FormControl>
    </div>
  );
}