import styles from "../../../styles/KanbanCard.module.scss"
import trashIcon from "./icons/trash.svg";
import { IoCloseOutline } from "react-icons/io5";

export const DeletePlayerModal = ({ isModalOpen, setModalOpen, confirmDeletion }) => 
    isModalOpen && (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <IoCloseOutline
            className={styles.closeButton}
            onClick={() => setModalOpen(false)}
            size="24px"
          />
          <h3>Delete player</h3>
          <p>Are you sure you want to delete this player from your pipeline?</p>
          <div className={styles.modalActions}>
            <button className={styles.deleteButton} onClick={confirmDeletion}>
              <img
                className={styles.deleteIcon}
                alt="trash icon"
                src={trashIcon}
              />
              Delete
            </button>
            <button
              className={styles.cancelButton}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
