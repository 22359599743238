import { action, makeObservable, observable, runInAction } from "mobx";
import { CollaborationService, OperationBuilder } from "../services/CollaborationService";
import { Board, BoardCard, BoardStage } from "../model/Kanban";

export class KanbanStore {
  isReady: boolean;
  board: Board;
  scoutId: string;
  recruitingClass: number;

  private collaborationService: CollaborationService;

  constructor(collaborationService: CollaborationService, scoutId: string) {
    this.collaborationService = collaborationService;

    this.isReady = false;
    this.board = {stages: []};

    makeObservable(this, {
      isReady: observable,
      board: observable,
      loadBoard: action,
    });

    this.collaborationService.onUpdate = this.onUpdateBoard.bind(this);
  }

  async loadBoard(recruitingClass: number): Promise<void> {
    this.recruitingClass = recruitingClass;    
    try {
      await this.collaborationService.subscribe();
      if (this.collaborationService.document["board" + recruitingClass] === null) {

        await this.createBoard(recruitingClass);
      }
      runInAction(() => {
        this.board = this.collaborationService.document["board" + recruitingClass];
        this.isReady = true;
      })
  } catch (e) {
      console.log(e);
    }
  }

  async createBoard(recruitingClass: number): Promise<void> {
    const board = {
        stages: [
            {
              title: "Recruiting",
              cards: [],
            },
            {
              title: "Invitation",
              cards: [],
            },
            {
              title: "Negotiation",
              cards: [],
            }
          ],         
    };

    const op = new OperationBuilder();
    op.insertObject([], "board" + recruitingClass, board);
    await this.collaborationService.submitOperation(op);
  }

  addCard(stageIdx: number, card: BoardCard) {
    const op = new OperationBuilder();
    op.insertBefore(["board" + this.recruitingClass, "stages", stageIdx, "cards"], 0, card);
    this.collaborationService.submitOperation(op);
  }

  removeCard(stageIdx: number, cardIdx: number) {
    const op = new OperationBuilder();
    const card = this.collaborationService.document["board" + this.recruitingClass]["stages"][stageIdx]["cards"][cardIdx];
    const userId = card.user.id;
    op.deleteAt(["board" + this.recruitingClass, "stages", stageIdx, "cards"], cardIdx, card);
    this.collaborationService.submitOperation(op);
    return userId;
  }
  updateCard(stageIdx: number, cardIdx: number, card: BoardCard) {
    const op = new OperationBuilder();
    const oldCard = this.collaborationService.document["board" + this.recruitingClass]["stages"][stageIdx]["cards"][cardIdx];
    const userId = card.user.id;
    op.deleteAt(["board" + this.recruitingClass, "stages", stageIdx, "cards"], cardIdx, oldCard)
    .insertBefore(["board" + this.recruitingClass, "stages", stageIdx, "cards"], cardIdx, card);;
    this.collaborationService.submitOperation(op);
    return userId;
  }
  moveCard(fromStageIdx: number, fromCardIdx: number, toStageIdx: number, toCardIdx: number) {
    const op = new OperationBuilder();
    const card = this.collaborationService.document["board" + this.recruitingClass]["stages"][fromStageIdx]["cards"][fromCardIdx];
    if (fromStageIdx === toStageIdx) {
      if (toCardIdx > fromCardIdx) {
        toCardIdx--;
      }
      op.move(["board" + this.recruitingClass, "stages", fromStageIdx, "cards"], fromCardIdx, toCardIdx);
    } else {
      op.deleteAt(["board" + this.recruitingClass, "stages", fromStageIdx, "cards"], fromCardIdx, card)
        .insertBefore(["board" + this.recruitingClass, "stages", toStageIdx, "cards"], toCardIdx, card);
    }
    this.collaborationService.submitOperation(op);
  }

  addStage(stage: BoardStage) {
    const op = new OperationBuilder();
    const stages = this.collaborationService.document["board" + this.recruitingClass]["stages"];
    op.insertBefore(["board" + this.recruitingClass, "stages"], stages.length, stage);
    this.collaborationService.submitOperation(op);
  }

  removeStage(stageIdx: number) {
    const op = new OperationBuilder();
    const stage = this.collaborationService.document["board" + this.recruitingClass]["stages"][stageIdx];
    op.deleteAt(["board" + this.recruitingClass, "stages"], stageIdx, stage);
    this.collaborationService.submitOperation(op);
  }

  renameStage(stageIdx: number, toName: string) {
    const op = new OperationBuilder();
    const stageTitle = this.collaborationService.document["board" + this.recruitingClass]["stages"][stageIdx]["title"];
    op.replaceObject(["board" + this.recruitingClass, "stages", stageIdx], "title", stageTitle, toName);
    this.collaborationService.submitOperation(op);
  }

  moveStage(fromIdx: number, toIdx: number) {
    const op = new OperationBuilder();
    const stage = this.collaborationService.document["board" + this.recruitingClass]["stages"][fromIdx];
    op.move(["board" + this.recruitingClass, "stages", fromIdx], fromIdx, toIdx);
    this.collaborationService.submitOperation(op);
  }


  onUpdateBoard() {
    runInAction(() => {
      this.board = this.collaborationService.document["board" + this.recruitingClass];
    });
  }
}

