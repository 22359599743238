import * as React from 'react';
import organizationApi from '../../appsync/organizationApi';
import { useEffect, useState } from 'react';
import styles from "../../styles/Settings.module.scss";
import { Workspace } from './Workspace';
import { useScout } from './../Layout';


export type SettingsProps = {

  }

export function SettingsContainer(props: SettingsProps) {
  const [organization, setOrganization] = useState(null);
  const [name, setName] = useState("");
  const [workspace, setWorkspace] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [fetchNewData, setFetchNewData] = useState(true);
  const { scoutUser } = useScout();
 
  useEffect(()=>{
    if (scoutUser && scoutUser.scoutId && fetchNewData) {

      organizationApi.getOrganization(scoutUser.scoutId).then((org: any)=>{
        setOrganization(org.data.getOrganization);
      });   
      setFetchNewData(false);   
    }
  }, [scoutUser, fetchNewData]);
  useEffect(()=>{
    if (organization) {
      setName(organization.Name);
      setOrganizationId(organization.Id);
      setWorkspace(organization.Workspace);    
      setLogoUrl(organization.LogoUrl);
    }

  }, [organization]);


  function handleSave(){
    setFetchNewData(true);
  }

  return <div className={styles.containerDiv}>
   <Workspace 
    OrganizationId={organizationId}
    WorkspaceUrl={workspace}
    OrgName={name}
    LogoName={logoUrl}
    HandleProfileSave={handleSave}
   />
  </div>;
}