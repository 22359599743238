import {  useState } from "react";
import styles from "../../styles/Account.module.scss";
import { CustomButton } from "../Button";
import MultipleSelectChip from "../MultipleSelectChip";
import { Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import UserApi from "../../appsync/userApi";
import DeleteIcon from '@mui/icons-material/Delete';
declare type AddPlayerProps = {
  handleClose: any;  
  showAddDialog: boolean;
  organizationId: number;
}

export type PlayerLink = {
  Url:string,
  LinkText: string,
  LinkOrder:number,
}
export function AddPlayerDialog(props: AddPlayerProps) {
  const [positions, setPositions] = useState([]);
  const [newLinks, setNewLinks] = useState<PlayerLink[]>([])
  const [linkCounter, setLinkCounter] = useState(0);
  const [newPlayerObj, setNewPlayerObj] = useState({
    email:{
      value:'',
      error:false,
      errorMessage:'You must enter a email address'
    },
    fullName:{
      value:'',
      error:false,
      errorMessage:'You must enter a name'
    },
    phone:{
      value:'',
      error:false,
      errorMessage:'You must enter a phone'
    },
    class: {
      value:0,
      error:false,
      errorMessage:'You must enter a class'
    },
    teamName: {
      value:'',
      error:false,
      errorMessage:'You must enter a team name'
    }
  });

  const handlePositionChange = (values: string[]) => {
  setPositions(values);
  }

  
  const handleRemoveLink = (e) => {
    setLinkCounter(linkCounter - 1);
    setNewLinks(newLinks.slice(parseInt(e.target.name), 1));
  }
  const handleChangeUrl = (e) => {
    setLinkCounter(linkCounter + 1);
    let newUrl = e.target.value;
    newLinks[parseInt(e.target.name)].Url = newUrl;
  }
  const handleChangeType = (e) => {
    setLinkCounter(linkCounter + 1);
    let newType = e.target.value;
    newLinks[parseInt(e.target.name)].LinkText = newType;
  }
  const handleAddNewLink = () => {
    setLinkCounter(linkCounter + 1);
    newLinks.push(
      {
        Url:"",
        LinkText: "",
        LinkOrder: newLinks.length,
      }
    );
  }
  const handleAddPlayer =(e) => {

    e.preventDefault();

    const formFields = Object.keys(newPlayerObj);
    let newFormValues = {...newPlayerObj}
    let formInvalid = false;
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = newPlayerObj[currentField].value;

      if(currentValue === '' || currentValue === 0){
        formInvalid = true;
        newFormValues = {
          ...newFormValues,
          [currentField]:{
            ...newFormValues[currentField],
            error:true
          }
        }
      }

    }
    setNewPlayerObj(newFormValues);
    if(formInvalid) return;
    const userInput = {
      DisplayName: newPlayerObj.fullName,
      Email: newPlayerObj.email,
      Phone: newPlayerObj.phone,
      Class: newPlayerObj.class,        
      ClubName: newPlayerObj.teamName,
      PlayerPositions: positions,
      Links:newLinks,
    };
      
    UserApi.importUser(props.organizationId, userInput).then((response)=>{
      props.handleClose(newPlayerObj.fullName);
    }); 

  }

   const updateDataWithText = (name: string) => (event) => {
    updateData({ name, value: event.target.value });
   };
   const updateData = (data) => {
    const { name, value } = data;
    setNewPlayerObj((state: any) => {
     return { ...state, [name]: value };
    });
   };   

  return (
    <Dialog open={props.showAddDialog} onClose={props.handleClose}>

      <DialogContent>
        <div className={styles.gridContainer}>
          <Grid  container spacing={2}>
            <Grid item xs={12}><h1>Add player manually</h1></Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: 300 }}>
                <TextField 
                required
                value={newPlayerObj?.fullName.value} 
                onChange={updateDataWithText("fullName")} 
                label="Full Name"
                variant="standard"
                error={newPlayerObj?.fullName.error}
                helperText={newPlayerObj?.fullName.error && newPlayerObj?.fullName.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: 300 }}>
                <TextField 
                required
                label="Email Address" 
                value={newPlayerObj?.email.value} 
                onChange={updateDataWithText("email")}
                variant="standard"
                error={newPlayerObj?.email.error}
                helperText={newPlayerObj?.email.error && newPlayerObj?.email.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: 300 }}>
                <TextField 
                required
                label="Phone" 
                value={newPlayerObj?.phone.value} 
                error={newPlayerObj?.phone.error}
                helperText={newPlayerObj?.phone.error && newPlayerObj?.phone.errorMessage}
                onChange={updateDataWithText("phone")}
                variant="standard"
                />
              </FormControl>
            </Grid>            
            <Grid item xs={12}>
            <Select
              required
              label="Class"
              error={newPlayerObj?.class.error}
              value={newPlayerObj?.class.value}
              onChange={updateDataWithText("class")}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
              <MenuItem value="2026">2026</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={12}>
              <MultipleSelectChip handleValueChange={handlePositionChange} />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <CustomButton onClick={handleAddNewLink} title="Add Player Link" />
              </Grid>
              {linkCounter > 0 && newLinks.map((link, index)=>(
                <Grid key={index} xs={10}
                sx={{
                  display: 'grid',
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : 'grey.100',
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                  border: '1px solid',
                  borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                  p: 1,
                  borderRadius: 2,
                  fontSize: '0.875rem',
                  fontWeight: '700',
                }}
              >
                  <Grid item xs={1}> 
                <IconButton onClick={handleRemoveLink} name={index.toString()} aria-label="delete"><DeleteIcon /></IconButton>
                </Grid>
                <Grid item xs={11}>
                    <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
                    <InputLabel id="link-type-select-label">Link Type</InputLabel>
                    <Select
                      labelId="link-type-select-label"
                      value={link.LinkText}
                      key={index}
                      name={index.toString()}
                      id="link-type-select"
                      onChange={(e) => handleChangeType(e)}
                    >
                        <MenuItem
                          key={1}
                          value={"Profile"}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          key={2}
                          value={"Highlight"}
                        >
                          Highlight
                        </MenuItem>            
                    </Select>
                  </FormControl> 
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ m: 1, width: 350 }}> 
                    <TextField
                    onChange={handleChangeUrl}
                    value={link.Url}
                    name={index.toString()}                
                    disabled={false}
                    title="URL"
                    variant="standard"
                    placeholder="Please enter link url starting with https://"
                    />
                  </FormControl>
              </Grid>
                </Grid>

              ))}

            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: 300 }}>
                <TextField 
                required
                label="Team Name" 
                value={newPlayerObj?.teamName.value} 
                onChange={updateDataWithText("teamName")} 
                error={newPlayerObj?.teamName.error}
                helperText={newPlayerObj?.teamName.error && newPlayerObj?.teamName.errorMessage}
                variant="standard"
                />
              </FormControl>
            </Grid>

          </Grid>
        </div>

      </DialogContent>
      <DialogActions>        
        <CustomButton title="Cancel" onClick={props.handleClose} />
        <CustomButton title="Add Player" onClick={handleAddPlayer} />
      </DialogActions>
    </Dialog>
  );
}
