import { User } from "../../model/User";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styles from "../../styles/PlayerPanel.module.scss";
import { InfoCell } from "./InfoCell";
import {
  differenceInYears,
} from "date-fns";

import { PHOTO_BUCKET_BASE_URL } from "../../enums/common";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import UserApi from "../../appsync/userApi";
import { IconButton, TextField } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { CustomButton } from "../Button";
import { WeightEditor } from "./WeightEditor";
import { HeightEditor } from "./HeightEditor";
import { FootEditor } from "./FootEditor";
import { PositionsEditor } from "./PositionsEditor";
import { BirthdayEditor } from "./BirthdayEditor";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Link } from "./Link";
import TextCell from "./TextCell";
import VideoCallIcon from '@mui/icons-material/VideoCall';
declare type PlayerPanelProps = {
  player: User;
  closeDrawer: any;
  stageIdx: number;
  cardIdx: number;
  propigateUpdate: any;
};
declare type Note = {
  Note: string;
  CreatorName: string;
  CreationTime: string;
  NoteId: number;
};

declare type LinkType = {
  Url: string;
  LinkText: string;
  LinkOrder: string;
};

export function PlayerPanel(props: PlayerPanelProps) {
  const { user } = useAuthenticator((context) => [context.user]);
  const player = props.player;
  const [id, setId] = useState("");
  const [playerNotes, setPlayerNotes] = useState<Note[]>();
  const [playerLinks, setPlayerLinks] = useState<LinkType[]>();
  const [noteEditorOpen, setNoteEditorOpen] = useState(false);
  const [linkEditorOpen, setLinkEditorOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [linkText, setLinkText] = useState("");
  const [urlText, setUrlText] = useState("");

  const openNoteEditor = () => {
    setNoteText("");
    setNoteEditorOpen(prevState => !prevState);  
  };

  const openLinkEditor = () => {
    setUrlText("");
    setLinkText("");
    setLinkEditorOpen(true);
  };
  const saveNote = () => {
    setNoteEditorOpen(false);
    const noteInput = {
      Text: noteText,
      TimeStamp: new Date().toLocaleString("en-US"),
      ScoutId: id,
    };
    UserApi.saveNote(player.id, noteInput).then(() => {
      UserApi.getUser(player.id, user.username).then((user: any) => {
        setPlayerNotes(user.data.getUser.Notes);
      });
    });
  };

  const saveLink = () => {
    setLinkEditorOpen(false);
    const linkOrder = playerLinks.length + 1;
    const linkInput = {
      LinkText: linkText,
      Url: urlText,
      LinkOrder: linkOrder,
      UserId: player.id,
    };
    UserApi.saveLink(player.id, linkInput).then(() => {
      UserApi.getUser(player.id, user.username).then((user: any) => {
        setPlayerLinks(user.data.getUser.Links);
      });
    });
  };

  const cancelSaveLink = () => {
    setLinkEditorOpen(false);
  };

  const handleNoteChange = (event) => {
    setNoteText(event.target.value);
  };
  const handleLinkTextChange = (event) => {
    setLinkText(event.target.value);
  };
  const handleUrlChange = (event) => {
    setUrlText(event.target.value);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setPlayerLinks(props.player.links);
  }, [props.player.links]);

  const handleTabChange = (index: number) => {
    if (index === 1) {
      setId(user.username);
      UserApi.getUser(player.id, user.username).then((user: any) => {
        setPlayerNotes(user.data.getUser.Notes);
      });
    }
    setSelectedTab(index);
  };

  const age = (birthDate) => {
    if (
      birthDate != null &&
      birthDate !== undefined &&
      birthDate !== "0001-01-01T00:00:00"
    ) {
      return differenceInYears(new Date(), new Date(birthDate)).toString();
    } else {
      return "-";
    }
  };


  const saveInfo = (value, field, type) => {
    if (type === "weight" || type === "height") {
      const userInput = {
        [field]: parseInt(value),
      };
      UserApi.saveUser(player.id, userInput).then((response) => {
        player.updated=false;
      });
    } else {
      const userInput = {
        [field]: value,
      };
      UserApi.saveUser(player.id, userInput).then((response) => {
        player.updated=false;
        props.propigateUpdate();
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <div className={styles.header}>
          <div>
            <IconButton onClick={props.closeDrawer} aria-label="cancel">
              <CancelOutlinedIcon />
            </IconButton>
          </div>
          <div className={styles.avatar}>
            <img
              alt="player"
              src={
                player.picture !== "/icons/nophoto.png"
                  ? `${PHOTO_BUCKET_BASE_URL}${player.picture}`
                  : player.picture
              }
              width="160"
              height="160"
            />
          </div>
          <div>
            <div className={styles.playerName}>{player.name}</div>
            <div className={styles.playerStage}>In Athlete Queue</div>
          </div>
        </div>

        <Tabs>
          <TabList className={styles.tabHead}>
            <Tab
              className={selectedTab !== 0 ? styles.inactiveTab : ""}
              onClick={() => handleTabChange(0)}
            >
              Player Card
            </Tab>
            <Tab
              className={selectedTab !== 1 ? styles.inactiveTab : ""}
              onClick={() => handleTabChange(1)}
            >
              Notes
            </Tab>
          </TabList>
          <TabPanel>
            <div className={styles.tabBody}>
              <div className={styles.playerInfo}>
                <h2 className={styles.cardTitle}>Player Info</h2>
                <TextCell
                    value={player.email}
                    label="Email"
                    readonly={true} 
                    handleSave={null}
                    field=""
                  />
                <TextCell
                  value={player.phone}
                  label={'Phone'}
                  readonly={false} 
                  handleSave={saveInfo}
                  field="Phone"                   
                />
              </div>
              <div className={styles.detailsColumns}>
                <div className={styles.detailsColumn}>
                  <TextCell
                    value={player.area}
                    label="Local Area"
                    readonly={false} 
                    handleSave={saveInfo}
                    field="Area"
                  />
                  <TextCell
                    value={age(player.birthDate)}
                    label="Age"
                    readonly={true}     
                    handleSave={saveInfo}
                    field=""
                  />
                  <HeightEditor
                    type="height"
                    queryField="PlayerHeight"
                    label={player.playerHeight}
                    sub="Height"
                    handleSave={saveInfo}
                  />
                  <PositionsEditor
                    type="position"
                    queryField="PlayerPositions"
                    label={player.playerPositions}
                    sub="Positions"
                    handleSave={saveInfo}
                  />
                </div>
                <div className={styles.detailsColumn}>
                <TextCell
                    value={player.country}
                    label="Nationality"
                    readonly={false}    
                    handleSave={saveInfo}
                    field="Country"
                  />
                  <BirthdayEditor
                    type="date"
                    queryField="Birthdate"
                    label={player.birthDate}
                    sub="Date of Birth"
                    handleSave={saveInfo}
                  />
                  <WeightEditor
                    type="weight"
                    queryField="PlayerWeight"
                    label={player.playerWeight}
                    sub="Weight"
                    handleSave={saveInfo}
                  />
                  <FootEditor
                    type="foot"
                    queryField="PlayerFoot"
                    label={player.playerFoot}
                    sub="Foot"
                    handleSave={saveInfo}
                  />
                </div>
              </div>

              <div className={styles.tabBody} style={{ textAlign: "left" }}>
                <div className={styles.videoSection}>
                  <h2>Links</h2>

                  {playerLinks == null ? (
                    <p className="bodyMedium">
                      No player video links have been added at the moment.
                    </p>
                  ) : (
                    playerLinks.map((link: LinkType) => (
                      <Link
                      key={link.LinkOrder}
                      type="text"
                      queryField="hudl"
                      label={link.LinkText}
                      sub={link.Url}
                      handleSave={saveInfo}
                    />
                     

                    ))
                  )}
              <IconButton onClick={openLinkEditor}>
                <VideoCallIcon />
              </IconButton>
              {linkEditorOpen && (
                <div>
                  <TextField
                    id="new-link-url"
                    onChange={handleUrlChange}
                    label="Add a new link url"
                  />
                  <TextField
                    id="new-link-text"
                    onChange={handleLinkTextChange}
                    label="Name your link"
                  />
                  <br />
                  <CustomButton onClick={saveLink} title="Save Link" />
                  <CustomButton onClick={cancelSaveLink} title="Cancel" />
                </div>
              )}
                </div>
              </div>

              <div className={styles.tabBody} style={{ textAlign: "left" }}>
                <div className={styles.experienceSection}>
                  <h2>Playing Experience</h2>

                  <InfoCell
                    type="text"
                    queryField="ClubName"
                    label={player.club}
                    sub=""
                    handleSave={saveInfo}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.bottomWrapper} style={{ textAlign: "left" }}>
              <div className={styles.notesSection}>
                {playerNotes == null ? (
                  <>
                    <p className="bodyNormal" style={{ fontWeight: "600" }}>
                      Nothing to see
                    </p>
                    <p className="bodyMedium">
                      No player notes have been added at the moment.
                    </p>
                  </>
                ) : (
                  playerNotes.map((note: Note) => (
                    <div key={note.NoteId} className={styles.noteContainer}>
                      <div className={styles.coachName}>
                        {note.CreatorName}
                        {"  "}
                        <span className={styles.creationTime}>
                          {note.CreationTime}
                        </span>
                      </div>
                      <div className={styles.note}>{note.Note}</div>
                    </div>
                  ))
                )}
                <IconButton onClick={openNoteEditor}>
                  <PostAddIcon />
                </IconButton>
                {noteEditorOpen && (
                  <div>
                    <TextField
                      id="new-note"
                      onChange={handleNoteChange}
                      label="Add a new note"
                      multiline
                      maxRows={10}
                      style={{marginBottom:'10px'}}
                    />
                    <br />
                    <CustomButton onClick={saveNote} title="Save Note" />
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}