import React from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Outlet, Link, useOutletContext } from "react-router-dom";
import {ReactComponent as PlayersIcon} from "../icons/player.svg";
import {ReactComponent as ScoutsIcon} from "../icons/scouts.svg";
import {ReactComponent as KanbanIcon} from '../icons/kanban.svg'
import {ReactComponent as AccountIcon} from '../icons/documents.svg';
import {ReactComponent as SettingsIcon} from '../icons/settings.svg';
import {ReactComponent as HelpIcon} from '../icons/help.svg';
import symbol from '../icons/symbol.svg';
import styles from "../styles/Layout.module.scss";
import Header from './Header';
import ScoutApi from '../appsync/scoutApi';
import organizationApi from '../appsync/organizationApi';
import {  useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import type { ScoutUser } from './types';

type ContextType = { scoutUser: ScoutUser | null };
export function useScout() {
  return useOutletContext<ContextType>();
}
export default function Layout() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [scoutUser, setScoutUser] = React.useState<ScoutUser | null>(null);
  const [displayUser, setDisplayUser] = useState("");
  const [organizaton, setOrganization] = useState();
  const [scoutId, setScoutId] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgLogoUrl, setOrgLogoUrl] = useState("");


  useEffect(() => {
    ScoutApi.getScout(user.username).then((dbUser:any)=>{
      const organizationId: number = dbUser.data.getScout.OrganizationId;
      setDisplayUser(dbUser.data.getScout.FirstName);     
      setScoutId(user.username);   
      if(! organizationId){
        signOut();
      }

      let scout: ScoutUser = {
        scoutId: user.username,
        organizationId: organizationId
      };
      setScoutUser(scout);

      organizationApi.getOrganization(user.username).then((org: any)=>{
        setOrganization(org.data.getOrganization);
        setOrgLogoUrl(org.data.getOrganization.LogoUrl);
        setOrgName(org.data.getOrganization.Name);
      });      
    });

   
  },[user.username, signOut]);

  return (
    <>

      <Header orgLogoUrl={orgLogoUrl} orgName={orgName} title="stuff" name={displayUser} />    
    <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

  <Sidebar className={styles.sidebar}>
      <Menu
        menuItemStyles={{
          button: {
            // the active class will be added automatically by react router
            // so we can use it to style the active menu item
            [`&.active`]: {
              backgroundColor: '#13395e',
              color: '#b6c8d9',
            },
          },
        }}
      >
        <MenuItem active={window.location.pathname === "/"}  icon={<PlayersIcon/>} component={<Link to="/" />}>Players</MenuItem>
        <MenuItem active={window.location.pathname === "/backlog"}  icon={<KanbanIcon/>} component={<Link to="/backlog" />}>Backlog</MenuItem>
        <MenuItem active={window.location.pathname === "/scouts"}  icon={<ScoutsIcon/>} component={<Link to="/scouts" />}>Scouts</MenuItem>
        <MenuItem active={window.location.pathname === "/account"}  icon={<AccountIcon/>} component={<Link to="/account" />}>Account</MenuItem>
        <MenuItem active={window.location.pathname === "/support"}  icon={<HelpIcon/>} component={<Link to="/support" />}>Support</MenuItem>
        <MenuItem active={window.location.pathname === "/settings"}  icon={<SettingsIcon/>} component={<Link to="/settings" />}>Settings</MenuItem>
      </Menu>
      <div className={styles.poweredBy}>
        <span className={styles.poweredByText}>Powered by </span>
        <img src={symbol} alt="symbol" width={110} height={21} />
      </div>
    </Sidebar>
    <main style={{ padding: '10px', width: '100%' }}> 

      <Outlet  context={{ scoutUser } satisfies ContextType} />
    </main>

    </div>
    </>  
    );
}