import * as React from 'react';
import { BoardCard } from '../../model/Kanban';
import { SortableTable } from './SortableTable';
import { CardMenuCallback } from '../kanban/KanbanTypes';

export type PlayerTableProps = {
  onCardMenu: CardMenuCallback;
  playerData: BoardCard[];
  addPlayer:any;
  organizationId: number;
  updateLead: any;
}
  
export function PlayerTable(props: PlayerTableProps) { 


  return (
    <SortableTable 
    updateLead={props.updateLead}
    rows={props.playerData}
    onCardMenu={props.onCardMenu}
    addPlayer={props.addPlayer}
    organizationId={props.organizationId}
    />
    );
}

