import { generateClient } from 'aws-amplify/api';
import * as queries from "../graphql/queries";

export default class OrganizationApi{

  static async getOrganization(userId:string){
    let orgData = null;// JSON.parse(sessionStorage.getItem("org" + userId));
    if (!orgData) {
      orgData = await this.getOrganizationFromServer(userId);
      //sessionStorage.setItem("org" + userId, JSON.stringify(orgData));
    }
    return orgData;
  }

  static async getOrganizationFromServer(userId:string){
    //this.configureAmplity();
    const client = generateClient();
    return await client.graphql({
      query: queries.getOrganization,
      variables: { id: `${userId}` },
    })
  }

  static async saveOrganization(userId:string, orgData:any){
   // this.configureAmplity();
    const query = queries.saveOrganization.replace('$id', userId).replace('$orgData',JSON.stringify(orgData));
    const client = generateClient();
    return await client.graphql({
      query: query.replace(/"([^(")"]+[^\\"]+)":/g, "$1:"),
    })
  }
}

