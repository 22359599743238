import React from "react";
import { useState } from "react";
import styles from "../../styles/InfoCell.module.scss";
import { Chip, IconButton, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export type FootEditorProps = {
  icon?: string;
  label: number;
  sub: string;
  queryField: string;
  type: string;
  handleSave: any;
};

export const FootEditor = (props: FootEditorProps) => {
  const [adjustedValue, setAdjustedValue] = useState<number | null>(props.label);
  const feet =  ["Either", "Right", "Left"];
  const feetNames = (playerFeet: number) => {

    if (playerFeet === null || playerFeet === undefined  || playerFeet > 2) {
      return "-";
    }
    const index = playerFeet
    const returnString = <Chip key={index} label={feet[index] + " "} variant="outlined" />;
    return returnString;
  }

  const[showInputElement, setShowInputElement] = useState(false);


  function handleFootChange(event){
    setAdjustedValue(parseInt(event.target.value));
  }
  function handleSave(){
    props.handleSave(adjustedValue, props.queryField, props.type);
    setShowInputElement(false);
  }
  function handleClick(){
    setShowInputElement(true);
  }
  function handleCancel() {
    setShowInputElement(false);
  }
  return <div className={styles.container}>
    <div className={styles.labelText}>
      <span onClick={handleClick}
          style={{
            display: "inline-block",
            height: "25px",
            minWidth: "150px",
          }}
        >
        {props.sub}
        </span>    
    </div>
    <div>
      <div>
        {showInputElement ? (
          <div>
            <ToggleButtonGroup
                  value={adjustedValue}
                  exclusive
                  onChange={handleFootChange}
                  aria-label="player foot"
                >
                  <ToggleButton value={0} aria-label="either">
                    Either
                  </ToggleButton>
                  <ToggleButton value={1} aria-label="right">
                    R
                  </ToggleButton>
                  <ToggleButton value={2} aria-label="left">
                    L
                  </ToggleButton>
                </ToggleButtonGroup>
            <div>
            <IconButton
            onClick={handleSave}
            aria-label="save">
              <CheckCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleCancel}
            aria-label="cancel">
              <CancelOutlinedIcon />
            </IconButton>
            </div>
            </div>
        ) : (
          <span
              onClick={handleClick}
              style={{
                display: "inline-block",
                height: "25px",
                minWidth: "150px",
              }}
            >
              {feetNames(adjustedValue)}
            </span> 
        )
        }
      </div>
    </div>
  </div>;
};