import styles from "../styles/Button.module.scss";
import React from "react";
export interface ButtonProps {
  onClick?: any;
  title?: string;
  className?: string;
}

export function CustomButton(props: ButtonProps) {
  return <button className={styles.button}
    onClick={props.onClick}
  >
    {props.title}
  </button>
}