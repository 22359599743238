import { Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import Home from './pages/home';
import Settings from './pages/settings';
import './styles/globals.scss';
import './styles/app.css';

import { MainStore, MainStoreContext } from "./stores/MainStore";
import Backlog from './pages/backlog';
import Scouts from './pages/scouts';
import Account from './pages/account';
import GetHelp from './pages/get-help';



const mainStore = new MainStore();

export default function App() {

  return (

      <MainStoreContext.Provider value={mainStore}>
        <div>


          {/* Routes nest inside one another. Nested route paths build upon
                parent route paths, and nested route elements render inside
                parent route elements. See the note about <Outlet> below. */}
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="backlog" element={<Backlog />} />
              <Route path="scouts" element={<Scouts />} />
              <Route path="account" element={<Account />} />
              <Route path="support" element={<GetHelp />} />
              <Route path="settings" element={<Settings />} />
              
                 {/* Using path="*"" means "match anything", so this route
                    acts like a catch-all for URLs that we don't have explicit
                    routes for. */}
              <Route path="*" element={<Home />} />
            </Route>
          </Routes>
        </div>
      </MainStoreContext.Provider>

  );
}