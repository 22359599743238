import React from "react";
import styles from "../styles/FormTextField.module.scss";

interface FormTextFieldProps {
  title?: string;
  placeholder?: string;
  value?: string;
  name?: string;
  onChange?: any;
  disabled: boolean; 
}

export function FormTextField(props: FormTextFieldProps) {
  return <div>
    <div className={styles.label}>
      {props.title}
    </div>
    <div>
      <input disabled={props.disabled} onChange={props.onChange} name={props.name} value={props.value}  className={styles.input}
        placeholder={props.placeholder}
      />
    </div>
  </div>
}