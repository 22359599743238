import { Grid } from "@mui/material";
import styles from "../styles/Scouts.module.scss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import ScoutApi from "../appsync/scoutApi";
import { useScout } from "../components/Layout";


export default function Scouts() {

  const [orgScouts, setOrgScouts] = useState([]);
  const roleNames = ["Scout", "Supervisor", "Decider"] 
  const { scoutUser } = useScout();
  let gettingScouts = false;
  useEffect(() => {
    if (scoutUser && scoutUser.organizationId) {
      const organizationId: number = scoutUser.organizationId
      if (!gettingScouts) {
       getScouts(organizationId);
      }
        
    }
  }, [ scoutUser]);
  const getScouts = (organizationId: number) => {
        gettingScouts = true;
        ScoutApi.getScoutsFromServer(organizationId).then((scouts: any)=>{
          setOrgScouts(scouts.data.getScouts);
          gettingScouts = false;
        });      
  }

  return (
 <div className={styles.gridContainer}>
      <Grid  container spacing={2}>
        <Grid item xs={12}>
          <h1>Scouts</h1>
          {orgScouts != null && orgScouts.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orgScouts.map((row: any) => (
                  <TableRow
                    key={row.Email}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.FirstName + " " + row.LastName}
                    </TableCell>
                    <TableCell align="left">{row.Email}</TableCell>
                    <TableCell align="left">{roleNames[row.Role]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> )} 
        </Grid>
      </Grid>
    </div>
  );
}


