import React from "react";

import styles from "../../styles/ImageUpload.module.scss";
import { CustomButton } from "../Button";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import FileUploadDialog from "../file-upload/FileUpload";
import { useState } from "react";
import { ImageFileData } from "../file-upload/ImageFileData";
export interface ImageUploadProps {
  userPhotoFile?: string;
  handleUploadDone: any;
}

export function ImageUpload(props: ImageUploadProps) {
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  
  const handleImageUploadOpen = () => {
    setImageUploadOpen(true);
  };
    
  const handleDialogClose = (value: ImageFileData) => {
      setImageUploadOpen(false);
      if (value) {
        props.handleUploadDone(value);
      }
  };
  return <div className={styles.picFrame}>
{props.userPhotoFile !== null && props.userPhotoFile !== "" ?(
  <img className={styles.loadedPic} alt="userPhoto" width="80" height="80" src={process.env.REACT_APP_USER_IMAGE_BASE_URL + props.userPhotoFile} />
):(

<div className={styles.pic}>
   <AddPhotoAlternateOutlinedIcon></AddPhotoAlternateOutlinedIcon>

</div>                   
)}
   <FileUploadDialog
     selectedValue={selectedFile}
     open={imageUploadOpen}
     onClose={handleDialogClose}
    />
  <CustomButton title="Upload Photo" onClick={handleImageUploadOpen}></CustomButton>          
</div>
}