import { Grid } from "@mui/material";
import styles from "../styles/Get-Help.module.scss";

export default function GetHelp() {
  return <>
    <div className={styles.container}>
    <p className="p1"><span className="s1"><b>Welcome to Arenalinq Support!</b></span></p>
<p className="p2"><span className="s2"></span><br /></p>
<p className="p1"><span className="s1">For assistance, email us at <a href="mailto:support@arenalinq.com"><span className="s3">support@arenalinq.com</span></a>.</span></p>
<p className="p2"><span className="s2"></span><br /></p>
<p className="p1"><span className="s1"><b>Managing Recruits:</b></span></p>
<ul className="ul1">
  <li className="li1"><span className="s4"></span><span className="s1">Players Page: Organize recruits efficiently with the Kanban board.</span></li>
  <li className="li1"><span className="s4"></span><span className="s1">Backlog Page: The entry point for recruits into Arenalinq.</span></li>
</ul>
<p className="p2"><span className="s2"></span><br /></p>
<p className="p1"><span className="s1"><b>Players Page:</b></span></p>
<ul className="ul1">
  <li className="li1"><span className="s4"></span><span className="s1">Use the Kanban to organize recruits.</span></li>
  <li className="li1"><span className="s4"></span><span className="s1">Drag and drop recruits into columns.</span></li>
  <li className="li1"><span className="s4"></span><span className="s1">Customize columns by clicking “...” for options.</span></li>
  <li className="li1"><span className="s4"></span><span className="s1">Click on a player&apos;s name for more details.</span></li>
</ul>
<p className="p2"><span className="s2"></span><br /></p>
<p className="p1"><span className="s1"><b>Backlog Page:</b></span></p>
<ul className="ul1">
  <li className="li1"><span className="s4"></span><span className="s1">Import recruits with the &apos;+ Import Player&apos; button.</span></li>
  <li className="li1"><span className="s4"></span><span className="s1">Transfer recruits to the Players Kanban by selecting and clicking &apos;Add to Pipeline.&apos;</span></li>
  <li className="li1"><span className="s4"></span><span className="s1">Categorize recruits by changing their &apos;Lead.&apos;</span></li>
  <li className="li1"><span className="s4"></span><span className="s1">Sort and filter the list easily.</span></li>
</ul>
<p className="p2"><span className="s2"></span><br /></p>
<p className="p3"><span className="s2">Reach out to <a href="mailto:support@arenalinq.com"><span className="s3">support@arenalinq.com</span></a> for help.</span></p> 

    </div>
  </>;
}

