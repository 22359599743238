import { EnvironmentInfo } from "../model/EnvironmentInfo";

export class APIService {
  async getEnvironmentInfo(): Promise<EnvironmentInfo> {
    try {
      const response = await fetch("/api/env");
      if (response.status != 200) throw new Error("APIError");
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    }
  }
}
