
import { observer } from "mobx-react-lite";
import { Alert, Grid, Snackbar, TextField } from "@mui/material";

import { useEffect, useState } from "react";
import { CustomButton } from "../components/Button";
import styles from "../styles/Account.module.scss";
import UserApi from "../appsync/userApi";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ImageFileData } from "../components/file-upload/ImageFileData";
import { ImageUpload } from "../components/image-upload/imageUpload";
import ScoutApi from "../appsync/scoutApi";
const Account = observer(() => {

  const [id, setId] = useState("");
  const [clubId, setClubId] = useState("");
  const [fetchNewData, setFetchNewData] = useState(true);
  const { user } = useAuthenticator((context) => [context.user]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>();
  const [apiUser, setApiUser] = useState();
  const [open, setOpen] = useState(false);
  const [toastString, setToastString] = useState("");
  const [userPhotoFile, setUserPhotoFile] = useState("");
  const [notificationsChecked, setNotificationsChecked] = useState(true);


  useEffect(() => {
      setId(user.username);
  }, [user]); 
  useEffect(()=>{
    if (id && fetchNewData) {
      ScoutApi.getScout(id).then((scout: any)=>{
        setApiUser(scout.data.getScout);  
        setFirstName(scout.data.getScout.FirstName);
        setLastName(scout.data.getScout.LastName);
        setUserPhotoFile(scout.data.getScout.PhotoUrl);
      });
      setFetchNewData(false);   
    }
  }, [id, fetchNewData]);

  const handleSave = () =>{
    const scoutInput = {
      FirstName: firstName,
      LastName: lastName,
      PhotoUrl: userPhotoFile,
    };
    ScoutApi.saveScout(id, scoutInput).then((response)=>{
      setToastString("Account updates were successfully changed");
      setOpen(true);
    });
    
  }
  const handleUploadDone = (value: ImageFileData) => {
    setSelectedFile(value);
    const imageInput = {
        UserId: id,
        BinaryData: value.FileData,
        Name: value.FileName,
    };
      UserApi.uploadImage(imageInput).then((response: any)=>{
      setUserPhotoFile(response.data.imageUpload.Path)
      setToastString("Image Uploaded to Server");
      setOpen(true);
    });
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleNotificationCheckboxChange = (event) => {
    setNotificationsChecked(event.target.checked);
    const userInput = {
      SEmailNotifications: event.target.checked,
    };
    UserApi.saveUser(id, userInput).then((response)=>{
      setToastString("Notification preferences successfully changed");
      setOpen(true);
    });
  }
  return (
<>


    <div className={styles.gridContainer}>
      <Grid  container spacing={2}>
        <Grid item xs={12}>
          <h1>Account</h1>
          Manage your personal settings          
        </Grid>
        <Grid item xs={12}>
        <h2>Profile photo</h2>         
        </Grid>        
        <Grid item xs={6}>
          <ImageUpload 
          userPhotoFile={userPhotoFile}
          handleUploadDone={handleUploadDone}
          />       
        </Grid>
        <Grid item xs={6}>
          Add a profile photo. Recommended size is 256x256px.
        </Grid>
        <Grid item xs={12}>
          <h2>General</h2>          
        </Grid>
        <Grid item xs={12}>
          <TextField
                  value={firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(event.target.value);
                  }}
                  label="First Name"
                  id="account-first-name"
                  variant="outlined"
                />
        </Grid>
        <Grid item xs={12}>
          <TextField
                  value={lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(event.target.value);
                  }}
                  label="Last Name"
                  id="account-last-name"
                  variant="outlined"
                />
        </Grid>        <Grid item xs={12}>
          <CustomButton title="Save changes" onClick={handleSave} />          
        </Grid>
        {/* <Grid item xs={12}>
          <h2>Notifications</h2>
          <FormControlLabel 
            control={<Checkbox  
            onChange={handleNotificationCheckboxChange}
            checked={notificationsChecked}
            sx={{
              color: "#2C786C",
              '&.Mui-checked': {
                color: "#2C786C",
              },
            }} />} label="Recieve a weekly email digest" />          
        </Grid> */}
      </Grid>
    </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {toastString}
          </Alert>
        </Snackbar>
        </>
  );
});
export default Account;

