import {  IconButton} from "@mui/material";
import {  useState } from "react";
import styles from "../../styles/InfoCell.module.scss";
import type {} from '@mui/x-date-pickers/themeAugmentation';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
export type EditFieldProps = {
  value: string;
  label: string;
  handleSave: any;
  field: string;
  readonly: boolean;
};
function TextCell(props: EditFieldProps) {
  const [text, setText] = useState(props.value);
  const [showInputElement, setShowInputElement] = useState(false);
  const saveClick = () => {
    setShowInputElement(false);
    props.handleSave(text, props.field, "");
  }  
  const cancelClick = () => {
    setShowInputElement(false);
  }
  const handleClick = () =>  {
    if(props.readonly) return;
    setShowInputElement(true);
  }
  
  return(<>

<div className={styles.container}>
    <div className={styles.labelText}>
    <span onClick={handleClick}
        style={{
          display: "inline-block",
          height: "20px",
          minWidth: "170px",
        }}
      >
      {props.label}
      </span>    
    </div>
    <div style={{
          display: "inline-block",
          height: "20px",
          minWidth: "170px",
        }}
      >
      { showInputElement && (  
        <div className={styles.container}>
          <input
              type="text"
              value={text}
              onChange={(e)=>{
                setText(e.target.value);
              }}
              autoFocus
            />      
            <div className={styles.container}>
          <IconButton
          onClick={saveClick}
          aria-label="save">
            <CheckCircleOutlineOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={cancelClick}
          aria-label="cancel">
            <CancelOutlinedIcon />
          </IconButton>
          </div>        
        </div>    

      )}
      <div className={styles.break}></div>
      <div onClick={handleClick} className={styles.subText}>{text}</div>
    </div>
  </div>
  </>

  );

}

export default TextCell;