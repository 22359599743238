import React from "react";
import { useEffect, ChangeEvent, useState  } from "react";
import styles from "../../styles/Account.module.scss";
import { CustomButton } from "../Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import UserApi from "../../appsync/userApi";
import { Button, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { parse } from "csv-parse/browser/esm/sync";

type cvsItem = {
  id: string;
  value: string;
};

//Name,Club Team,Position,Email,Number
const columns = [
  {
    field: "id",
    headerName: "id"
  },
  {
    field: "name",
    headerName: "name"
  },
  {
    field: "club",
    headerName: "club"
  },
  {
    field: "position",
    headerName: "position"
  },
  {
    field: "email",
    headerName: "email"
  },
  {
    field: "phone",
    headerName: "phone"
  }
];

declare type ImportPlayersProps = {
  handleClose: any;  
  showAddDialog: boolean;
  userId: string;
  organizationId: number;
}

export function ImportPlayersDialog(props: ImportPlayersProps) {
   const [fullName, setFullName] = useState("");
   const [email, setEmail] = useState("");
   const [positions, setPositions] = useState([]);
   const [teamName, setTeamName] = useState("");
   const [csvData, setCsvData] = useState<cvsItem[]>([]);
   const [filename, setFilename] = useState("");
   const [selectedRecruittingClass, setSelectedRecruittingClass] = useState("2023");

   const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
     if (!e.target.files) {
       return;
     }
     const file = e.target.files[0];
     const { name } = file;
     setFilename(name);
 
     const reader = new FileReader();
     reader.onload = (evt) => {
       if (!evt?.target?.result) {
         return;
       }
       const { result } = evt.target;
       const records = parse(result as string, {
         columns: ["id", "name","club","position","email","phone"],
         delimiter: ",",
         trim: true,
         skip_empty_lines: true
       });
       setCsvData(records.splice(1, records.length - 1));
     };
     reader.readAsBinaryString(file);
   };
  
  const handleRecruitingClassChange = (event) => {
    setSelectedRecruittingClass(event.target.value);
  };


   const handleAddPlayers =() => {
    const players = [];
    const  positionEnum = ["LWB", "RWB", "D", "G", "GK", "K", "B", "CFB", "RFB", "LFB", "CB", "RCB", "LCB", "TCB", "ST", "CD", "SW", "L", "OFB", "OB", "RB", "LB", "M", "MF", "CHB", "RHB", "LHB", "CM", "AM", "CAM", "HM", "DM", "CDM", "PM", "OM", "RM", "LM", "WB", "WM", "F", "CF", "IF", "RI", "RF", "LI", "LF", "W", "RW", "LW", "S", "CS", "RCF", "LCF", "LCF", "TCF", "WF", "SS", "WS", "RWS", "LWS", "DCM"];


    csvData.forEach((player: any) => {
      const position = positionEnum.findIndex(x => x == player.position);
      const userInput = {
        DisplayName: player.name,
        Class: selectedRecruittingClass,
        Email: player.email,
        ClubName: player.club,
        PlayerPositions: [position],
        Phone: player.phone
      };
      players.push(userInput);
    })

      UserApi.importUsers(props.userId, props.organizationId, players).then((response)=>{
        props.handleClose(fullName);
      });


   }
  return (
    <Dialog open={props.showAddDialog} onClose={props.handleClose}>

      <DialogContent>
        <h1>Import players from CSV</h1>
        <div>
        <Button
        component="label"
        variant="outlined"
        startIcon={<UploadFileIcon />}
      >
        Upload CSV
        <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
      </Button>
      <Box>{filename}</Box>

      <DataGrid
        autoHeight
        rows={csvData}
        columns={columns}
        hideFooter
        sx={{ mt: 1 }}
        getRowId={(row: any) =>  row.email + row.salary}
      />
        </div>
      <h2>Class</h2>
      <Select
        label="Class"
        value={selectedRecruittingClass}
        onChange={handleRecruitingClassChange}
        size="small"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="2023">2023</MenuItem>
        <MenuItem value="2024">2024</MenuItem>
        <MenuItem value="2025">2025</MenuItem>
        <MenuItem value="2026">2026</MenuItem>
      </Select>
      </DialogContent>
      <DialogActions>        
        <CustomButton title="Cancel" onClick={props.handleClose} />
        <CustomButton title="Add Players" onClick={handleAddPlayers} />
      </DialogActions>
    </Dialog>
  );
}
