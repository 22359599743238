import { PlayerTable } from "../components/player-queue/PlayerTable";
import { PlayerPanel } from "../components/player/PlayerPanel";
import { BoardCard } from "../model/Kanban";
import styles from '../styles/Backlog.module.scss';
import { MainStoreContext } from "../stores/MainStore";
import { useContext, useEffect, useMemo, useState, memo } from "react";
import { User } from "../model/User";
import { useAuthenticator } from "@aws-amplify/ui-react";
import UserApi from "../appsync/userApi";
import {
  Alert,
  Box,
  Snackbar,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Drawer,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { AddPlayerDialog } from "../components/player/AddPlayer";
import { ImportPlayersDialog } from "../components/player/CSVImport";
import { useScout } from "../components/Layout";

function Backlog() {
  const mainStore = useContext(MainStoreContext);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [overlayUser, setOverlayUser] = useState(null);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [orgPlayers, setOrgPlayers] = useState([]);
  const [orgId, setOrgId] = useState(0);
  const [recruitingClass, setRecruitingClass] = useState(2024);
  const [open, setOpen] = useState(false);
  const [toastString, setToastString] = useState("");
  const [store, setStore] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedRecruittingClass, setSelectedRecruittingClass] = useState("");
  const [selectedLead, setSelectedLead] = useState<number>(4);
  const [filteredPlayerData, setFilteredPlayerData] = useState([]);
  const [showSearchFields, setShowSearchFields] = useState(false);
  const { scoutUser } = useScout();

  useEffect(() => {
    if (scoutUser && scoutUser.organizationId) {
      setOrgId(scoutUser.organizationId);
      mainStore.fetchStaticData(scoutUser.organizationId, recruitingClass);        
    }
  },[scoutUser, recruitingClass, mainStore]);


  useEffect(() => {
    if(mainStore.isReady){
      setStore(mainStore.kanbanStore);
    }
    if(store && !store.isReady){
      store.loadBoard(recruitingClass);      
    }
  }, [mainStore, mainStore.kanbanStore, store, user.username, signOut]);


  let gettingPlayers = false;
  useEffect(() => {
    if (!gettingPlayers) {
      updateBacklogTable(user.username);
    }
  }, [user.username]);
  const updateBacklogTable = (cognitoUserName) =>{
    gettingPlayers = true;    
    UserApi.getPlayers(cognitoUserName).then((players: any) => {
      setOrgPlayers(players.data.getPlayers);
      gettingPlayers = false;
    });
  }
  const playerData = useMemo(() => {
    let i;
    const boardCards: BoardCard[] = [];
    for (i = 0; i < orgPlayers.length; i++) {
      const element = orgPlayers[i];
      const iuser: User = {
        id: element.Id,
        name: element.DisplayName,
        playerPositions: element.PlayerPositions,
        email: element.Email,
        phone: element.Phone,
        lead: element.Lead,
        class: element.Class,
        inKanban: element.InKanban,
        picture: "/icons/nophoto.png",
        birthDate: element.Birthdate,
        club: element.ClubName,
        area: element.Area,
        country: element.Country,
        playerHeight: element.PlayerHeight,
        playerWeight: element.PlayerWeight,
        links: element.Links,
        notes: element.Notes,
      };

      const boardCard: BoardCard = {
        id: element.Id,
        user: iuser,
      };

      boardCards.push(boardCard);
    }

    return boardCards;
  }, [orgPlayers]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchEmail("");
    setSelectedPosition("");
    setSelectedRecruittingClass("");
    setSelectedLead(4);
  };

  const handleEmailSearchChange = (event) => {
    setSearchEmail(event.target.value);
  };

  const handlePositionChange = (event) => {
    setSelectedPosition(event.target.value);
  };

  const handleRecruitingClassChange = (event) => {
    setSelectedRecruittingClass(event.target.value);
  };

  const handleReset = () => {
    setSearchQuery("");
    setSearchEmail("");
    setSelectedPosition("");
    setSelectedRecruittingClass("");
    setSelectedLead(4);
  };

  useEffect(() => {
    let filtered = playerData;

    if (searchQuery !== "") {
      filtered = filtered.filter((player) =>
        player.user.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase().trim())
      );
    }

    if (searchEmail !== "") {
      filtered = filtered.filter((player) =>
        player.user.email?.toLowerCase()
          .includes(searchEmail.toLowerCase().trim())
      );
    }

    if (selectedPosition !== "") {
      filtered = filtered.filter((player) =>
        player.user.playerPositions.includes(parseInt(selectedPosition))
      );
    }

    if (selectedRecruittingClass !== "") {
      filtered = filtered.filter(
        (player) => String(player.user.class) === selectedRecruittingClass
      );
    }

    if (selectedLead !== 4) {
      filtered = filtered.filter((player) => player.user.lead === selectedLead);
    }

    setFilteredPlayerData(filtered);
  }, [
    playerData,
    searchQuery,
    searchEmail,
    selectedPosition,
    selectedRecruittingClass,
    selectedLead,
  ]);

  const onUpdateLead = (id: string, value: number) => {
    const player = playerData.find((x) => x.id == id);
    if (player) {
      player.user.lead = value;
    }
  };
  const onViewProfile = (card: BoardCard) => {

    UserApi.getPlayer(card.user.id, user.username).then((player: any) => {
      const element = player.data.getPlayerDetail;
      const selectedUser: User = {
        id: element.Id,
        name: element.DisplayName,
        playerPositions: element.PlayerPositions,
        email: element.Email,
        phone: element.Phone,
        lead: element.Lead,
        class: element.Class,
        inKanban: element.InKanban,
        picture: "/icons/nophoto.png",
        birthDate: element.Birthdate,
        club: element.ClubName,
        country: element.Country,
        area: element.Area,
        playerHeight: element.PlayerHeight,
        playerWeight: element.PlayerWeight,
        playerFoot: element.PlayerFoot,
        links: element.Links,
        notes: element.Notes,
      };
      setOverlayUser(selectedUser);

      toggleDrawer();
    });

    setShowAddPanel(false);
  };
  const onShowAddPlayer = () => {
    setShowAddPanel(true);
  };
  const handleClose = () => {
    updateBacklogTable(user.username);
    setShowAddPanel(false);
  };
  const onShowImport = () => {
    setShowImport(true);
  };
  const handleCloseImport = () => {
    updateBacklogTable(user.username);
    setShowImport(false);
  };

  const handleToastClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [drawerState, setDrawerState] = useState(false);

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  // 0      1     2   3    4    5   6   7   8   9    10    11     12    13   14   15   16   17  18  19   20    21   22   23  24  25  26   27   28   29     30   31   32    33  34   35    36    37    38  39   40  41  42  43  44 45  46    47    48
//["LWB", "RWB", "D", "", "GK", "", "", "", "", "", "CB", "RCB", "LCB", "", "ST", "", "SW", "", "", "", "RB", "LB", "M", "", "", "", "", "CM", "", "CAM", "HM", "", "CDM", "", "", "RM", "LM", "WB", "", "F", "", "", "", "", "", "", "W", "RW", "LW", "", "", "", "", "", "", "", "", "", "", "", ""]
  const positions = [
    { id: 5, label: "GK" },
    { id: 17, label: "SW" },
    { id: 11, label: "CB" },
    { id: 22, label: "LB" },
    { id: 21, label: "RB" },
    { id: 1, label: "LWB" },
    { id: 2, label: "RWB" },
    { id: 33, label: "CDM" },
    { id: 12, label: "RCB" },
    { id: 13, label: "LCB" },
    { id: 30, label: "CAM" },
    { id: 37, label: "LM" },
    { id: 36, label: "RM" },
    { id: 15, label: "ST" },
    { id: 49, label: "LW" },
    { id: 48, label: "RW" },
    { id: 3, label: "D" },
    { id: 40, label: "F" },
    { id: 23, label: "M" },
    { id: 47, label: "W" },  
    { id: 28, label: "CM" },  
    { id: 31, label: "HM" },    
    { id: 38, label: "WB" },       
  ];
  const propigateUpdate = ()=>{

  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowSearchFields(!showSearchFields)}
          style={{
            marginRight: "20px",
            fontSize: "12px",
            padding: "6px 12px",
            backgroundColor: "#489688",
            height: "28px",
            width: "150px",
            display: "flex",
            boxShadow: 'none',
          }}
        >
          {showSearchFields ? "Hide Filters" : "Filter Players"}
        </Button>
        <Button onClick={onShowAddPlayer} startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    style={{
                      marginRight: "20px",
                      fontSize: "12px",
                      padding: "6px 12px",
                      backgroundColor: "#489688",
                      height: "28px",
                      width: "150px",
                      display: "flex",
                      boxShadow: 'none',
                    }}>
            Import Player
          </Button>
            {/* <Button onClick={onShowImport} startIcon={<AddIcon />}
                      variant="contained"
                      color="primary"
                      style={{
                        marginRight: "20px",
                        fontSize: "12px",
                        padding: "6px 12px",
                        backgroundColor: "#489688",
                        height: "28px",
                        width: "150px",
                        display: "flex",
                        boxShadow: 'none',
                      }}>
              CSV Import
            </Button>             */}
      </div>
    {showSearchFields && (
      <>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReset}
            style={{
              marginLeft: "16px",
              marginTop: "8px",
              marginBottom: "-25px",
              fontSize: "12px",
              padding: "6px 12px",
              backgroundColor: "#489688",
              height: "28px",
              width: "100px",
              boxShadow: 'none',
            }}
          >Reset
          </Button>
          <TextField
            label="Name"
            variant="standard"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginLeft: "16px" }}
            size="small"
          />
          <TextField
            label="Email"
            variant="standard"
            value={searchEmail}
            onChange={handleEmailSearchChange}
            style={{ marginLeft: "16px" }}
            size="small"
          />
          <FormControl
            variant="standard"
            style={{ marginLeft: "16px", minWidth: 180 }}
            size="small"
          >
            <InputLabel>Class</InputLabel>
            <Select
              label="Class"
              value={selectedRecruittingClass}
              onChange={handleRecruitingClassChange}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
              <MenuItem value="2026">2026</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            style={{ marginLeft: "16px", minWidth: 100 }}
            size="small"
          >
            <InputLabel>Position</InputLabel>
            <Select
              label="Position"
              value={selectedPosition}
              onChange={handlePositionChange}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {positions.map((position) => (
                <MenuItem key={position.id} value={position.id}>
                  {position.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            style={{ marginLeft: "16px", minWidth: 100 }}
            size="small"
          >
            <InputLabel>Lead</InputLabel>
            <Select
              label="Lead"
              value={selectedLead}
              onChange={(event) => {
                const value = event.target.value;
                setSelectedLead(value === "" ? 4 : Number(value));
              }}
            >
              <MenuItem value={4}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={0}>New</MenuItem>
              <MenuItem value={1}>Cold</MenuItem>
              <MenuItem value={2}>Warm</MenuItem>
              <MenuItem value={3}>Hot</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
          )}

          {filteredPlayerData.length > 0 ? (
            <PlayerTable
              onCardMenu={onViewProfile}
              updateLead={(id, value) => {
                onUpdateLead(id, value);
              }}
              playerData={filteredPlayerData}
              addPlayer={(stageIdx: number, cards: BoardCard[]) => {
                cards.forEach((card) => {
                  store.addCard(stageIdx, card);
                  const player = playerData.find((x) => x.id == card.user.id);
                  if (player) {
                    player.user.inKanban = true;
                  }
                });
              }}
              organizationId={orgId}
            />
          ) : searchQuery ||
            searchEmail ||
            selectedPosition ||
            selectedRecruittingClass ||
            selectedLead !== 4 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                color: "#004445",
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              No results found
              <span
                style={{
                  color: "#4E5E5D",
                  fontWeight: "400",
                  fontSize: "16px",
                  width: "400px",
                  textAlign: "center",
                }}
              >
                Sorry, we couldn&apos;t find any results on your search. Please
                refine and try again.
              </span>
            </div>
          ) : null}
      <AddPlayerDialog organizationId={orgId} handleClose={handleClose} showAddDialog={showAddPanel} />
      <ImportPlayersDialog organizationId={orgId} userId={user.username} handleClose={handleCloseImport} showAddDialog={showImport} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert
          onClose={handleToastClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {toastString}
        </Alert>
      </Snackbar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerState}
        onClose={toggleDrawer}
      >
        <PlayerPanel 
        closeDrawer={toggleDrawer} 
        player={overlayUser} 
        stageIdx={0} 
        cardIdx={0} 
        propigateUpdate={propigateUpdate} 
        />
      </Drawer>
    </div>
  );
}


export default memo(Backlog);