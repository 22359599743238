import { generateClient } from 'aws-amplify/api';
import * as queries from "../graphql/queries";

export default class ScoutApi{

  static async getScout(cognitoId:string){
    let scoutData = null;// = JSON.parse(sessionStorage.getItem("scout" + cognitoId)); 
    if (!scoutData) {
      scoutData = await this.getScoutFromServer(cognitoId);
      //sessionStorage.setItem("scout" + cognitoId, JSON.stringify(scoutData) );
    }
    return scoutData;
  }
  static async getScoutFromServer(cognitoId:string){
   // this.configureAmplity();
    const client = generateClient();
    return await client.graphql({
      query: queries.getScout,
      variables: { id: `${cognitoId}` },
    })
  }

  static async getScoutsFromServer(organizationId: number){
   // this.configureAmplity();
    const client = generateClient();
    return await client.graphql({
      query: queries.getScouts,
      variables: { organizationId: `${organizationId}` },

})
} 
  
  static async saveScout(cognitoId: string, scoutData: any){
   // this.configureAmplity();
    const query = queries.saveScout.replace('$id', cognitoId).replace('$scoutData',JSON.stringify(scoutData));
    const client = generateClient();
    return await client.graphql({
      query: query.replace(/"([^(")"]+[^\\"]+)":/g, "$1:"),
    });
  }

  static async uploadImage(imageData: any){
   // this.configureAmplity();
    const query = queries.uploadImage.replace('$imageData',JSON.stringify(imageData).replace("}", "").replace("{", ""));
    const client = generateClient();
    return await client.graphql({
      query: query.replace(/"([^(")"]+[^\\"]+)":/g, "$1:"),
    })
  }

}

