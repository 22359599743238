import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button'
import { visuallyHidden } from '@mui/utils';
import { CardMenuCallback } from '../kanban/KanbanTypes';
import { User } from '../../model/User';
import { BoardCard } from '../../model/Kanban';
import styles from "../../styles/SortableTable.module.scss";
import { Chip, FormControl, MenuItem, Select } from '@mui/material';
import UserApi from '../../appsync/userApi';
import { useEffect, useState } from 'react';

export type SortableTableProps = {
    onCardMenu: CardMenuCallback;
    rows: BoardCard[];
    addPlayer: any;
    organizationId: number;
    updateLead: any;
}

interface PlayerTableData {
  id: string;
  name: string;
  picture: string,
  playerHeight: number;
  playerPositions: string[],
  playerWeight: number;
  playerFoot: string;
  birthDate: Date;
  card: BoardCard;
  class: number;
  email: string;
  lead: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | string[] | Date | BoardCard},
  b: { [key in Key]: number | string | string[] | Date | BoardCard },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof PlayerTableData;
  label: string;
  numeric: boolean;
}
  
const headCells: readonly HeadCell[] = [
  {
    id: 'lead',
    numeric: true,
    disablePadding: false,
    label: 'Lead',
  }, 
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Player',
  },
  {
    id: 'class',
    numeric: true,
    disablePadding: false,
    label: 'Class of',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'playerPositions',
    numeric: false,
    disablePadding: false,
    label: 'Position',
  }
];
  
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof PlayerTableData) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof PlayerTableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
  
interface EnhancedTableToolbarProps {
  numSelected: number;
  addPlayer: any;
}
  
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      variant='dense'
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} player{numSelected > 1 ? 's': ''} selected
        </Typography>
      ) : (
        <></>
      )}
      {numSelected > 0 ? (
          <Button onClick={props.addPlayer}
          style={{
            marginLeft: "16px",
            marginTop: "8px",
            fontSize: "12px",
            padding: "6px 12px",
            backgroundColor: "#489688",
            color: "white",
            height: "28px",
            width: "180px",
            boxShadow: 'none',
          }}>Add to Pipeline</Button>
      ) : (<></>
      )}
    </Toolbar>
  );
};
export function SortableTable(props: SortableTableProps) {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof PlayerTableData>('lead');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const positions =  ["LWB", "RWB", "D", "G", "GK", "K", "B", "CFB", "RFB", "LFB", "CB", "RCB", "LCB", "TCB", "ST", "CD", "SW", "L", "OFB", "OB", "RB", "LB", "M", "MF", "CHB", "RHB", "LHB", "CM", "AM", "CAM", "HM", "DM", "CDM", "PM", "OM", "RM", "LM", "WB", "WM", "F", "CF", "IF", "RI", "RF", "LI", "LF", "W", "RW", "LW", "S", "CS", "RCF", "LCF", "LCF", "TCF", "WF", "SS", "WS", "RWS", "LWS", "DCM"];
  const [rows, setRows] = useState([]);

  const positionNames = (playerPositions: string[]) => {
    if (playerPositions === null || playerPositions.length < 1) {
      return "";
    }
    const returnString = playerPositions.map(position => {
      const index = parseInt(position);
      return <Chip key={index} label={positions[index -1] + " "} variant="outlined" />;
    });
    return returnString;
  }

  useEffect(()=> {
    setupData();
  }, [props.rows]);

  const setupData = () => {
    const tableRows = props.rows.filter(x => x.user.inKanban != true).map(player => {
        return {
          id: player.user.id,
          name: player.user.name,
          picture: player.user.picture,
          email: player.user.email,
          playerPositions: player.user.playerPositions,
          playerHeight: player.user.playerHeight,
          playerWeight: player.user.playerWeight,
          playerFoot: player.user.playerFoot,
          birthDate: player.user.birthDate,
          lead:player.user.lead,
          class: player.user.class,
          card: getBoardCard(player.user)
        }; 
  });
    setRows(tableRows);
  }
  function getBoardCard(user: User): BoardCard{
    return {
      id: 'id' + user.id,
      user: user
    };
  }
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PlayerTableData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleAddPlayerClick = () => {
    const selectedPlayers = rows.filter(x => selected.includes(x.id));
    const playerArray: BoardCard[] = []; 
    selectedPlayers.forEach(element => {
      playerArray.push(element.card);

      const userInput = {
        InKanban: true,
        OrganizationId: props.organizationId
      };
      UserApi.saveUser(element.id, userInput).then((response)=>{
        setSelected([]);
      });      
    });
    props.addPlayer(0, playerArray);
    setupData();

  }
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const leadChange = (event, rowid) => {
    const id = rowid;
    const lead = event.target.value;
    props.updateLead(id, lead);
    setupData();
    const userInput = {
      Lead: lead,
      OrganizationId: props.organizationId
    };
    UserApi.saveUser(id, userInput).then((response)=>{

    });
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
    <Paper sx={{ width: '100%', mb: 2 }}>
      <EnhancedTableToolbar 
      addPlayer={handleAddPlayerClick} 
      numSelected={selected.length} 
      />
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300 }}
          aria-labelledby="tableTitle"
          size={'small'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: PlayerTableData, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 70 }}>
                        <Select
                          labelId="playerlead"
                          id="playerlead"
                          value={row.lead}
                          label="Lead"
                          disableUnderline
                          onChange={(event) => leadChange(event, row.id)}
                        >
                          <MenuItem value={0}>
                            <em>New</em>
                          </MenuItem>
                          <MenuItem value={1}>Cold</MenuItem>
                          <MenuItem value={2}>Warm</MenuItem>
                          <MenuItem value={3}>Hot</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div className={styles.layout}  onClick={() => props.onCardMenu(row.card)}>
                        <div className={styles.name}>
                        {row.name}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="right"><Chip label={row.class} variant="outlined" /></TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{positionNames(row.playerPositions)}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  </Box>      
  );
}