import { observer } from "mobx-react-lite";
import { useEffect, useContext, useState } from "react";
import { MainStoreContext } from "../stores/MainStore";
import React from "react";
import UserApi from "../appsync/userApi";
import {useAuthenticator } from "@aws-amplify/ui-react";
import {  Alert, Snackbar } from "@mui/material";
import KanbanContainer from "../components/kanban/KanbanContainer";
import { useScout } from "../components/Layout";

const Home = observer(() => {
  const mainStore = useContext(MainStoreContext);
  const {user, signOut} = useAuthenticator((context) => [context.user]);
  const [orgId, setOrgId] = useState(0);
  const [scoutId, setScoutId] = useState("");
  const [store, setStore] = useState(null);
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [toastString, setToastString] = useState("");
  const recruitingClass = 2024;
  const { scoutUser } = useScout();

  useEffect(() => {
    if (scoutUser && scoutUser.organizationId && scoutUser.scoutId) {
        const organizationId = scoutUser.organizationId;
        setScoutId(scoutUser.scoutId);   

        setOrgId(organizationId);
        if (organizationId > 0) {
          mainStore.fetchStaticData(organizationId, recruitingClass);        
        }      
    }
   
  },[ recruitingClass, scoutUser, mainStore]);
  const updateCard = (stageIdx, cardIdx, card) => {
    store.updateCard(stageIdx, cardIdx, card)
  }
  useEffect(() => {
    if(mainStore.isReady){
      setStore(mainStore.kanbanStore);
    }
    if(store){
      store.loadBoard(recruitingClass).then(() => {
        setReady(true);
      });      
    }
  }, [mainStore, mainStore.kanbanStore, store, user.username, signOut]);
  


  const handleRemovePlayer = (id) => {
      const userInput = {
        InKanban: false,
        OrganizationId: orgId,
      };
      UserApi.saveUser(id, userInput).then((response)=>{
        setToastString("Player was removed from the list and was added to backlog");
        setOpen(true);
      });      
  }
  const handleToastClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  return (
    <>
      {ready ?<KanbanContainer 
      recruitingClass={recruitingClass} 
      orgId={orgId} 
      handleRemovePlayer={handleRemovePlayer}
      scoutId={scoutId} 
      updateCard={updateCard}
      /> :<></> }
      
      <Snackbar open={open} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
          {toastString}
        </Alert>
      </Snackbar>
    </>);
});
export default Home;
