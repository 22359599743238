export const getUser = `
query GetUser($id: String!, $scoutId: String!) {
  getUser(id:  $id, scoutId:  $scoutId){
    DisplayName
    Notes {
      Note
      CreatorName
      CreationTime
      NoteId
    }
    Links {
      Url
      LinkText
      LinkOrder
    }
 }
}
`;

export const getScout = `
query GetScout($id: String!) {
  getScout(id:  $id){
    FirstName
    LastName
    CognitoId
    PhotoUrl
    OrganizationId
 }
}
`;

export const getScouts = `
query GetScouts($organizationId: String!) {
  getScouts(organizationId:  $organizationId){
    FirstName
    LastName
    Email
    Role
    PhotoUrl
    OrganizationId
 }
}
`;

export const saveUser = `mutation SaveUser{
  saveUser(id: "$id", data: "$userData")
}`;

export const importUser = `mutation ImportUser{
  importPlayer(organizationId: $organizationId, data: "$userData")
}`;

export const importUsers = `mutation ImportPlayers{
  importPlayers(organizationId: $organizationId, id: "$id", data: "$userData")
}`;

export const saveNote = `mutation SaveNote{
  saveNote(id: "$id", data: $noteData)
}`;

export const saveLink = `mutation SaveLink{
  saveLink(id: "$id", data: $linkData)
}`;


export const saveScout = `mutation SaveScout{
  saveScout(id: "$id", data: $scoutData)
}`;

export const getUserClub = `query GetUserClub($id: String!)  {
  getUserClub(userId: $id) {
    ClubId
    ClubLocation
    ClubName
    LogoThumbnailUrl
    LogoUrl
  }
}`;

export const getOrganization = `query getOrganization($id: String!)  {
  getOrganization(id: $id) {
    Id
    Name
    Phone
    Workspace
    LogoUrl
  }
}`;


export const uploadImage = `query imageUpload {
    imageUpload($imageData){
      Path
    }
}`;

export const saveUserClub = `mutation MyMutation{
  saveClub(id: "$id", data: $clubData)
}
`;

export const saveOrganization = `mutation MyMutation{
  saveOrganization(id: "$id", data: $orgData)
}
`;
export const getPlayers = `query MyQuery($scoutId: String!) {
  getPlayers(scoutId: $scoutId){
    DisplayName
    Id
    Email
    Lead
    Area
    ClubName
    Class
    PlayerPositions
  }
}`;

export const getPlayer = `
query GetPlayer($id: String!, $scoutId: String!) {
  getPlayerDetail(id:  $id, scoutId:  $scoutId){
    Id
    Bio
    Birthdate
    Country
    Area
    ClubName
    Email
    Phone
    PlayerHeight
    PlayerFoot
    PlayerPosition
    PlayerPositions
    PlayerWeight
    PhotoUrl
    DisplayName
    Links {
      Url
      LinkText
      LinkOrder
    }
 }
}`;