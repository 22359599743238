import React, { useState, useEffect, useRef } from "react";
import { useDrop } from "react-dnd";
import moreIcon from "../../icons/more.svg";

import { KanbanItemTypes } from "./constants";
import { BoardStage } from "../../model/Kanban";
import { KanbanCard } from "./KanbanCard";

import styles from "../../styles/KanbanStage.module.scss";
import {
  MoveCardCallback,
  CardMenuCallback,
  RenameStageCallback,
  DeleteCardCallback,
  DeleteStageCallBack,
  MoveStageCallback,
} from "./KanbanTypes";

type KanbanStageProps = {
  stage: BoardStage;
  stageIdx: number;
  stages: BoardStage[];
  onMoveCard: MoveCardCallback;
  onRenameStage: RenameStageCallback;
  onDeleteCard: DeleteCardCallback;
  onDeleteStage: DeleteStageCallBack;
  onMoveStage: MoveStageCallback;
  updateCard: any;
  scoutId: string;
};

export function KanbanStage(props: KanbanStageProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [stageName, setStageName] = useState(props.stage.title);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const [collectedDrop, drop] = useDrop(() => ({
    accept: KanbanItemTypes.CARD,
    drop: (item, monitor) => {
      props.onMoveCard(
        item["stageIdx"],
        item["cardIdx"],
        props.stageIdx,
        props.stage.cards.length
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
  }));

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOptionsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const isOver = collectedDrop.isOver;

  function rename() {
    if (isEditing) {
      props.onRenameStage(props.stageIdx, stageName);
    }
    setIsEditing(!isEditing);
  }

  function toggleOptions() {
    setIsOptionsOpen(!isOptionsOpen);
  }

  function handleEditTitleOption() {
    setIsOptionsOpen(false);
    setIsEditing(true);
  }

  function handleDeleteStage() {
    if (props.stage.cards.length > 0) {
      alert("Remove all players before deleting the list.");
    } else {
      props.onDeleteStage(props.stageIdx);
    }
  }

  function handleMoveLeft() {
    props.onMoveStage(props.stageIdx, props.stageIdx - 1);
  }

  function handleMoveRight() {
    props.onMoveStage(props.stageIdx, props.stageIdx + 1);
  }

  const cards = props.stage.cards.map((card, idx) => (
    <KanbanCard
      key={`KanbanCard-${props.stageIdx}-${idx}`}
      card={card}
      stages={props.stages}
      stageIdx={props.stageIdx}
      cardIdx={idx}
      onMoveCard={props.onMoveCard}
      onDeleteCard={props.onDeleteCard}
      scoutId={props.scoutId}
      updateCard={props.updateCard}
    />
  ));

  return (
    <div className={styles.stage}>
      <div className={styles.header}>
        <div className={styles.title} ref={ref}>
          {isEditing ? (
            <div className={styles.editingContainer}>
              <input
                className={styles.editInput}
                type="text"
                value={stageName}
                onChange={(e) => setStageName(e.target.value)}
                autoFocus
              />
              <button
                className={styles.saveButton}
                onClick={() => {
                  props.onRenameStage(props.stageIdx, stageName);
                  setIsEditing(false);
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <>
              <span>{props.stage.title}</span>
              <div className={styles.optionsContainer}>
                <img
                  src={moreIcon}
                  alt="edit columns"
                  onClick={toggleOptions}
                  className={styles.dropDown}
                />
                {isOptionsOpen && (
                  <div className={styles.optionsBox}>
                    <div
                      className={styles.option}
                      onClick={handleEditTitleOption}
                    >
                      Edit stage title
                    </div>
                    {/* <div className={styles.option} onClick={handleMoveLeft}>
                      Move list left
                    </div>
                    <div className={styles.option} onClick={handleMoveRight}>
                      Move list right
                    </div> */}
                    <div
                      className={`${styles.option} ${styles.deleteOption}`}
                      onClick={handleDeleteStage}
                    >
                      Delete list
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {props.stageIdx === 0 && cards.length < 1
        ? "Go to the Backlog and add players to get started."
        : cards}
      <div className={styles.cardDrop} ref={drop}>
        {isOver && <div className={styles.cardPlaceholder}></div>}
      </div>
    </div>
  );
}
