import React from "react";
import { useState } from "react";
import styles from "../../styles/InfoCell.module.scss";
import EditField from "../EditField";

export type LinkProps = {
  icon?: string;
  label: string;
  sub: string;
  queryField: string;
  type: string;
  handleSave: any;
};

export const Link = (props: LinkProps) => {
  let text = "-"
  const [newValue, setNewValue] = useState();
  const[showInputElement, setShowInputElement] = useState(false);

  if(props.label != null && props.label !== undefined && props.label !== ""){
    text = props.label;
  }

  return <div className={styles.container}>
    <div>
      <div className={styles.labelText}>
      <EditField 
      type={props.type}
      value={text}
      handleClick={() =>
        {
          setShowInputElement(true);
        }
      }
      handleChange={(value) => {
        text = value;
        setNewValue(value);
      }}

      handleSave={() =>
        {
          setShowInputElement(false);
          props.handleSave(newValue, props.queryField, props.type);
        }
      }
      handleCancel={() =>
        {
          setShowInputElement(false);
        }
      }      
      showInputEle={showInputElement}
      />
      </div>
      <div className={styles.linkText}><a href={props.sub} target="_blank" rel="noreferrer">{props.sub}</a></div>
    </div>
  </div>;
};
