
import { generateClient } from 'aws-amplify/api';
import * as queries from "../graphql/queries";

export default class UserApi{

  static async getUser(userId: string, scoutId: string){
   // this.configureAmplity();
    const client = generateClient();
    return await client.graphql({
      query: queries.getUser,
      variables: {id: `${userId}`,  scoutId: `${scoutId}`},

    })
  }

  static async saveUser(userId: string, userData: any){
   // this.configureAmplity();
    const query = queries.saveUser.replace('$id', userId).replace('$userData',btoa(JSON.stringify(userData)));
    const client = generateClient();
    return await client.graphql({
      query: query,

    })
  }

  static async importUser(organizationId: number, userData: any){
  // this.configureAmplity();
    const query = queries.importUser.replace('$organizationId', organizationId.toString()).replace('$userData',btoa(JSON.stringify(userData)));
    const client = generateClient();
    return await client.graphql({
      query: query,

    })
  }

  static async importUsers(userId: string, organizationId: number, userData: any){
   // this.configureAmplity();
    const query = queries.importUsers.replace('$id', userId).replace('$organizationId', organizationId.toString()).replace('$userData',btoa(JSON.stringify(userData)));
    const client = generateClient();
    return await client.graphql({
      query: query,
    })
  }  

  static async saveNote(userId: string, noteData: any){
    //this.configureAmplity();
    const client = generateClient();
    const query = queries.saveNote.replace('$id', userId).replace('$noteData',JSON.stringify(noteData));
    return await client.graphql({
      query: query.replace(/"([^(")"]+[^\\"]+)":/g, "$1:"),
    })
  }

  static async saveLink(userId: string, linkData: any){
   // this.configureAmplity();
    const client = generateClient();
    const query = queries.saveLink.replace('$id', userId).replace('$linkData',JSON.stringify(linkData));
    return await client.graphql({
      query: query.replace(/"([^(")"]+[^\\"]+)":/g, "$1:"),
    })
  }

  static async uploadImage(imageData: any){
   // this.configureAmplity();
    const client = generateClient();
    const query = queries.uploadImage.replace('$imageData',JSON.stringify(imageData).replace("}", "").replace("{", ""));
    return await client.graphql({
      query: query.replace(/"([^(")"]+[^\\"]+)":/g, "$1:"),

    })
  }

  static async getPlayers(scoutId: string){
   // this.configureAmplity();
    const client = generateClient();
    return await client.graphql({
      query: queries.getPlayers,
      variables: { scoutId: `${scoutId}` },     

    })
  }
  static async getPlayer(id: string, scoutId: string){
    //this.configureAmplity();
    const client = generateClient();
    return await client.graphql({
      query: queries.getPlayer,
      variables: { id: `${id}` ,  scoutId: `${scoutId}`},      

    })
  }

}

