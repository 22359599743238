import React from "react";
import { useEffect, useState } from "react";
import styles from "../../styles/InfoCell.module.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export type BirthdayEditorProps = {
  icon?: string;
  label: Date;
  sub: string;
  queryField: string;
  type: string;
  handleSave: any;
};

export const BirthdayEditor = (props: BirthdayEditorProps) => {
  const [value, setValue] = useState<Dayjs | null>(
    dayjs(),
  );
  const [displayValue, setDisplayValue] = useState(new Date());
  useEffect(()=> {
    if(props.label != null){
      setValue(dayjs(props.label))
      setDisplayValue(new Date(props.label));    
    }


  }, [props.label]);



  const[showInputElement, setShowInputElement] = useState(false);

  const handleBirtdayChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    setDisplayValue(newValue.toDate());
  };
  function handleSave(){
      props.handleSave(value, props.queryField, props.type);
      setShowInputElement(false);      
  }
  function handleClick(){
    setShowInputElement(true);
  }
  function handleCancel() {
    setShowInputElement(false);
  }
  return <div className={styles.container}>
    <div className={styles.labelText}>
    <span onClick={handleClick}
        style={{
          display: "inline-block",
          height: "25px",
          minWidth: "150px",
        }}
      >
      {props.sub}
      </span>    
    </div>
    <div>
      <div>
        {showInputElement ? (
          <div>
              
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
              label="Birth Date"
              value={value}
              disableOpenPicker={true}
              format="MM/DD/YYYY"
              onChange={handleBirtdayChange}
            />
          </LocalizationProvider>  
            <div>
            <IconButton
            onClick={handleSave}
            aria-label="save">
              <CheckCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleCancel}
            aria-label="cancel">
              <CancelOutlinedIcon />
            </IconButton>
            </div>
            </div>
        ) : (
          <span
            onClick={handleClick}
            style={{
              display: "inline-block",
              height: "25px",
              minWidth: "150px",
            }}
            >
            {displayValue && displayValue.getTime() !== new Date("0001-01-01T00:00:00").getTime() ? displayValue.toLocaleDateString(): "-"}
            </span>
        )
        }
      </div>
    </div>
  </div>;
};