import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import plusIcon from "../../icons/plus.svg";
import { Board, BoardStage } from "../../model/Kanban";
import { KanbanStage } from "./KanbanStage";
import styles from "../../styles/KanbanBoard.module.scss";
import {
  MoveCardCallback,
  RenameStageCallback,
  DeleteCardCallback,
  DeleteStageCallBack,
  MoveStageCallback,
} from "./KanbanTypes";

type KanbanBoardProps = {
  board: Board;
  onMoveCard: MoveCardCallback;
  onRenameStage: RenameStageCallback;
  onDeleteStage: DeleteStageCallBack;
  onDeleteCard: DeleteCardCallback;
  onMoveStage: MoveStageCallback;
  addStage: any;
  updateCard: any;
  organizationId: number;
  scoutId: string;
};

export function KanbanBoard(props: KanbanBoardProps) {
  const [showStageCreation, setShowStageCreation] = useState(false);
  const [newStageTitle, setNewStageTitle] = useState("");
  const stages = props.board.stages.map((stage, idx) => (
    <KanbanStage
      key={`KanbanStage-${idx}`}
      stages={props.board.stages}
      stage={stage}
      stageIdx={idx}
      onMoveCard={props.onMoveCard}
      onRenameStage={props.onRenameStage}
      onDeleteStage={props.onDeleteStage}
      onDeleteCard={props.onDeleteCard}
      onMoveStage={props.onMoveStage}
      updateCard={props.updateCard}
      scoutId={props.scoutId}
    />
  ));
  const handleOpenStageCreation = () => {
    setShowStageCreation(true);
  };

  const handleAddStage = () => {
    if (newStageTitle.trim() !== "") {
      const newStage: BoardStage = {
        title: newStageTitle,
        cards: [],
      };
      props.addStage(newStage);
      setNewStageTitle("");
      setShowStageCreation(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.board}>
        {stages}

        {showStageCreation ? (
          <div className={styles.stageCreation}>
            <input
              type="text"
              value={newStageTitle}
              onChange={(e) => setNewStageTitle(e.target.value)}
              placeholder="Enter stage title"
            />
            <div>
              <button
                className={`${styles.addListBtn} ${
                  newStageTitle.trim() === "" && styles.disabled
                }`}
                onClick={handleAddStage}
              >
                Add list
              </button>
              <button 
              className={styles.cancelBtn}
              onClick={() => setShowStageCreation(false)}>
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <button
          className={`${styles.addStageBtn} ${styles.centeredText}`} 
            onClick={handleOpenStageCreation}
          >
            <img src={plusIcon} height={15} width={15} alt="Add stage" className={styles.plusIcon} />
            Add another stage
          </button>
        )}
      </div>
    </DndProvider>
  );
}
