import React from "react";
import { FormTextField } from "../FormTextField";
import { CustomButton } from "../Button";
import OrganizationApi from '../../appsync/organizationApi';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";
import { ImageUpload } from "../image-upload/imageUpload";
import { ImageFileData } from "../file-upload/ImageFileData";
import UserApi from "../../appsync/userApi";
import styles from "../../styles/Settings.module.scss";
import { Alert, Snackbar } from "@mui/material";
export type ProfileProps = {
  OrganizationId: string;
  WorkspaceUrl: string;
  OrgName: string;
  LogoName: string;
  HandleProfileSave: any;
}

export function Workspace(props: ProfileProps) {

  const [orgName, setOrgName] = useState(props.OrgName);
  const [workspaceUrl, setWorkspaceUrl] = useState(props.WorkspaceUrl);
  const [logoPhotoFile, setLogoPhotoFile] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>();
  const [open, setOpen] = useState(false);
  const [toastString, setToastString] = useState("");

  
  useEffect(()=>{
    if (props.LogoName && props.LogoName.length > 0) {
        setLogoPhotoFile(props.LogoName);
    }

    setOrgName(props.OrgName);
    setWorkspaceUrl(props.WorkspaceUrl);
  }, [props]);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "logoName":
        setLogoPhotoFile(value);
        break;
      case "orgName":
        setOrgName(value);
        break;
      case "workspaceUrl":
        setWorkspaceUrl(value);
        break;
      
      default:
        break;
    }
  }
  function handleDelete(){

  }
  const handleUploadDone = (value: ImageFileData) => {
    setSelectedFile(value);
    const imageInput = {
        UserId: props.OrganizationId,
        BinaryData: value.FileData,
        Name: "org_" + value.FileName.replaceAll(" ", ""),
    };
      UserApi.uploadImage(imageInput).then((response: any)=>{
      setLogoPhotoFile(response.data.imageUpload.Path)
      setToastString("Image Uploaded to Server");
      setOpen(true);
    });
  }
  function handleSave(){
    const orgInput = {
      Workspace: workspaceUrl,
      Name: orgName,
      LogoUrl: logoPhotoFile,
    };

    OrganizationApi.saveOrganization(props.OrganizationId, orgInput).then((response)=>{
      props.HandleProfileSave();
    });
  }
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (  <div className={styles.rootdiv}>
    <h1>Workspace</h1>
    Manage your workspace settings
    <Grid container spacing={2}>
      <Grid item xs={12}>        <h2>Logo</h2>
</Grid>
      <Grid item xs={4}>
        <ImageUpload 
          userPhotoFile={logoPhotoFile}
          handleUploadDone={handleUploadDone}
          />       
        </Grid>
        <Grid item xs={8}>
        Add your organization&apos;s logo. Recommended size is 256x256px.
        </Grid>
      <Grid item xs={12}>
        <FormTextField disabled={false} onChange={handleInputChange} 
        name="orgName" value={orgName} 
        title="Organization Name" 
        placeholder="Your Organization Name"></FormTextField>
      </Grid>        
      <Grid item xs={12}>
        <FormTextField disabled={true}  onChange={handleInputChange} name="workspaceUrl" value={workspaceUrl} title="Workspace Url" />
      </Grid>
      <Grid item xs={4}>
        <CustomButton onClick={handleSave} title="Save Changes" />
      </Grid>      
      <Grid item xs={8}></Grid>
      {/* <Grid item xs={2}>
        <Button onClick={handleDelete} title="Delete Workspace" />
      </Grid>     */}
    </Grid>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {toastString}
          </Alert>
        </Snackbar>
  </div>);

}